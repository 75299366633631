<template>
  <div>
    <el-dialog
      :visible.sync="showIndicationDetail"
      :before-close="onHideModal"
      width="70%"
      center
      :fullscreen="isMobile || isTablet"
    >
      <div v-loading="isLoading">
        <div class="relative text-black" v-if="indication">
          <div
            slot="title"
            class="flex justify-between items-center border-b border-l-0 border-t-0 border-r-0 border-solid border-gray-200 mb-2 pb-2"
          >
            <div class="text-lg font-bold">{{ indication.name }}</div>
            <!-- <div class="text-sm italic">{{ indication.code }}</div> -->
          </div>
          <div class="mb-2">
            <div class="row">
              <div class="col-md-4">
                <div class="flex mb-2">
                  <div class="fw-500 fs-15">{{ $t("Họ và tên") }}:</div>
                  <div class="ml-2 fs-15">{{ personData.name }}</div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="flex mb-2">
                  <div class="fw-500 fs-15">{{ $t("Ngày sinh") }}:</div>
                  <div class="ml-2 fs-15" v-if="personData.birthday">
                    {{
                      personData.birthday
                        ? `${appUtils.formatDate(personData.birthday)}
                    (${mdtUtils.getAges(new Date(personData.birthday))})`
                        : ""
                    }}
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="flex mb-2">
                  <div class="fw-500 fs-15">{{ $t("Giới tính") }}:</div>
                  <div class="ml-2 fs-15" v-if="personData.gender">
                    {{ mdtUtils.getGender(personData.gender, "vi") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">
                <div class="flex mb-2">
                  <div class="fw-500 fs-15">{{ $t("Địa chỉ") }}:</div>
                  <div class="ml-2 fs-15 flex-1" v-if="personData.address">
                    {{ personData.address }}
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="flex mb-2">
                  <div class="fw-500 fs-15">{{ $t("Số điện thoại") }}:</div>
                  <div class="ml-2 fs-15" v-if="personData.phone">
                    {{ personData.phone }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div
              :class="
                type === 1 && tableData.length && hasResult
                  ? 'col-md-5'
                  : 'col-md-12'
              "
            >
              <div class="sticky" style="top: 40px">
                <div class="mb-3 hidden">
                  <h4 class="fs-15">{{ $t("Các chỉ số định lượng") }}</h4>
                  <div class="hodo-ckeditor__big">
                    <!-- <EditorInput
                      id="quantitativeIndex-indication"
                      :prop_text="form.quantitativeIndex"
                      @onChangeContent="
                        (content) => (form.quantitativeIndex = content)
                      "
                    ></EditorInput> -->
                  </div>
                </div>
                <div
                  class="mb-3"
                  v-if="type === 2 && tableData.length && hasResult"
                >
                  <h4 class="fs-15">{{ $t("Kết quả chẩn đoán") }}</h4>
                  <div class="mb-2">
                    {{ tableData[0].description }}
                  </div>
                </div>
                <div class="mb-3">
                  <h4 class="fs-15">{{ $t("Các tệp kết quả") }}</h4>
                  <div class="mb-2">
                    <input
                      type="file"
                      @change="onSelectedFile($event)"
                      class="hidden"
                      hidden
                      ref="inputFileIndication"
                      multiple
                    />
                    <input
                      type="file"
                      @change="onSelectedFileDicom($event)"
                      class="hidden"
                      hidden
                      accept=".zip, .rar"
                      ref="inputFileIndicationDicom"
                      multiple
                    />
                    <div class="flex flex-col">
                      <div
                        v-if="form.files.length"
                        class="flex flex-wrap -mx-2"
                      >
                        <div
                          v-for="(file, index) in form.files"
                          :key="file.id || index"
                          :class="file.type === 4 ? 'w-full' : ''"
                        >
                          <div
                            v-if="file.type === 4"
                            class="flex flex-col gap-2 mb-3 px-2"
                          >
                            <div class="flex items-center flex-1">
                              <div
                                class="flex relative bg-gray-50 hover:bg-gray-100 transition-all duration-200 cursor-pointer border border-solid border-gray-200 rounded-md w-16 h-16"
                              >
                                <span
                                  v-if="modifiable"
                                  @click="
                                    onDeleteIndicationAttachWithType({
                                      id: file.id,
                                      index,
                                      type: 1,
                                    })
                                  "
                                  class="flex absolute z-20 top-0 right-0 -mt-2 -mr-2 w-3 h-3 rounded-full bg-gray-300 hover:bg-red-500 hover:text-white transition-all duration-200"
                                >
                                  <i class="el-icon-close m-auto" />
                                </span>
                                <span
                                  @click="openWindow(file.file_name)"
                                  class="flex w-full text-zero overflow-hidden"
                                >
                                  <img
                                    v-if="isImage(file)"
                                    class="w-full h-full object-cover transform origin-center rounded-md"
                                    :src="
                                      file.file_name ||
                                      onHandleCreateObjectURL(file)
                                    "
                                    :alt="file.name"
                                  />
                                  <span
                                    v-else
                                    class="m-auto text-xl font-bold uppercase whitespace-nowrap"
                                  >
                                    {{ getExtensionOfFile(file.name) }}
                                  </span>
                                </span>
                              </div>
                              <div class="ml-2 w-96" v-if="modifiable">
                                <el-input
                                  placeholder="Nhập tên file"
                                  v-model="file.name"
                                  clearable
                                ></el-input>
                              </div>
                            </div>
                            <video width="400" controls>
                              <source :src="file.resultUrl" type="video/mp4" />
                            </video>
                          </div>
                          <div
                            v-else-if="!file.pac_id"
                            class="flex flex-col gap-2 mb-3 px-2"
                          >
                            <div class="flex items-center">
                              <div
                                class="flex relative bg-gray-50 hover:bg-gray-100 transition-all duration-200 cursor-pointer border border-solid border-gray-200 rounded-md w-16 h-16"
                              >
                                <span
                                  v-if="modifiable"
                                  @click="
                                    onDeleteIndicationAttachWithType({
                                      id: file.id,
                                      index,
                                      type: 1,
                                    })
                                  "
                                  class="flex absolute z-20 top-0 right-0 -mt-2 -mr-2 w-3 h-3 rounded-full bg-gray-300 hover:bg-red-500 hover:text-white transition-all duration-200"
                                >
                                  <i class="el-icon-close m-auto" />
                                </span>
                                <span
                                  @click="openWindow(file.file_name)"
                                  class="flex w-full text-zero overflow-hidden"
                                >
                                  <img
                                    v-if="isImage(file)"
                                    class="w-full h-full object-cover transform origin-center rounded-md"
                                    :src="
                                      file.file_name ||
                                      onHandleCreateObjectURL(file)
                                    "
                                    :alt="file.name"
                                  />
                                  <span
                                    v-else
                                    class="m-auto text-xl font-bold uppercase whitespace-nowrap"
                                  >
                                    {{ getExtensionOfFile(file.name) }}
                                  </span>
                                </span>
                              </div>
                              <div class="ml-2" v-if="modifiable">
                                <el-input
                                  placeholder="Nhập tên file"
                                  v-model="file.name"
                                  clearable
                                ></el-input>
                              </div>
                            </div>
                          </div>
                          <div v-else class="flex items-center mb-3 px-2">
                            <div
                              class="flex relative bg-gray-50 hover:bg-gray-100 transition-all duration-200 cursor-pointer border border-solid border-gray-200 rounded-md w-16 h-16"
                            >
                              <span
                                v-if="modifiable"
                                @click="
                                  onDeleteIndicationAttachWithType({
                                    id: file.id,
                                    index,
                                    type: 1,
                                  })
                                "
                                class="flex absolute z-20 top-0 right-0 -mt-2 -mr-2 w-3 h-3 rounded-full bg-gray-300 hover:bg-red-500 hover:text-white transition-all duration-200"
                              >
                                <i class="el-icon-close m-auto" />
                              </span>
                              <span
                                @click="openPac(file.pacs)"
                                class="flex w-full text-zero overflow-hidden"
                              >
                                <div
                                  class="selected-file-icon"
                                  style="
                                    background-image: url('/assets/images/icon/pac-icon.png');
                                  "
                                ></div>
                              </span>
                            </div>

                            <div
                              :set="(pacInfo = handleGetPacInfo(file))"
                              class="ml-2"
                              v-if="modifiable"
                            >
                              <el-input
                                placeholder="Nhập tên file"
                                v-model="file.name"
                                clearable
                              ></el-input>
                              <el-tag
                                size="mini"
                                :type="pacInfo.type"
                                effect="dark"
                                class="mt-1"
                                >{{ pacInfo.label }}</el-tag
                              >
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        v-if="form.links.length"
                        class="flex flex-wrap -mx-2"
                      >
                        <div
                          class="flex items-center px-2"
                          :class="modifiable ? 'w-1/4 mb-3' : 'mb-2 text-lg'"
                          v-for="(link, index) in form.links"
                          :key="link.id || index"
                        >
                          <template v-if="modifiable">
                            <el-form ref="formLinks" class="w-full">
                              <el-form-item
                                class="mb-0"
                                :label="$t('URL')"
                                required
                                :rules="[
                                  {
                                    required: true,
                                    message: 'Trường này không được để trống',
                                    trigger: 'blur',
                                  },
                                ]"
                              >
                                <el-input
                                  placeholder="Nhập link"
                                  v-model="link.url"
                                  clearable
                                ></el-input>
                              </el-form-item>
                              <el-form-item
                                class="mb-0"
                                :label="$t('Tên link')"
                              >
                                <el-input
                                  placeholder="Nhập tên link"
                                  v-model="link.name"
                                  clearable
                                ></el-input>
                              </el-form-item>
                            </el-form>
                            <div
                              v-if="modifiable"
                              @click="
                                onDeleteIndicationAttachWithType({
                                  id: link.id,
                                  index,
                                  type: 2,
                                })
                              "
                              role="button"
                              class="flex h-full bg-gray-100 hover:bg-red-100 hover:text-red-500 rounded-md ml-2 px-2 transition-all duration-200"
                            >
                              <div class="flex m-auto">
                                <i class="el-icon-close m-auto" />
                              </div>
                            </div>
                          </template>
                          <template v-else>
                            <i class="el-icon-link mr-1" />
                            <span
                              class="text-blue-800 hover:text-blue-600 transition-all duration-200 cursor-pointer"
                              @click="openFile(link)"
                              >{{ link.name || link.url }}</span
                            >
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <template>
                  <div class="mb-3">
                    <h4 class="fs-15">
                      <span>{{ $t("Mô tả") }}</span>
                    </h4>
                    <div
                      class="hodo-table"
                      v-if="!modifiable && examinationResults"
                      v-html="conclusion ? conclusion.description : ''"
                    ></div>
                    <!-- <div v-else class="hodo-ckeditor__default">
                      <TiptapEditor
                        :value="form.description"
                        @setValue="(t) => (form.description = t)"
                      />
                    </div> -->
                  </div>
                  <div class="mb-3">
                    <h4 class="fs-15">
                      <span>{{
                        $t(
                          isXQuang ? "Lời dặn của BS chuyên khoa:" : "Kết luận"
                        )
                      }}</span>
                    </h4>
                    <div
                      v-if="!modifiable && examinationResults"
                      v-html="conclusion ? conclusion.conclusion : ''"
                    ></div>
                    <!-- <div v-else class="hodo-ckeditor__default">
                      <TiptapEditor
                        :value="form.conclusion"
                        @setValue="(t) => (form.conclusion = t)"
                      />
                    </div> -->
                  </div>
                </template>
                <div class="mb-3" v-if="indication.sign">
                  <div class="flex flex-col items-end text-center">
                    <div class="mr-4">
                      <div class="mb-2">
                        {{ getHMDMYVN(indication.sign.sign_at) }}
                      </div>
                      <div class="uppercase font-bold">
                        {{ $t("Bác sĩ chỉ định") }}
                      </div>
                      <div class="mb-2 italic">
                        {{ $t("(Đã xem)") }}
                      </div>
                      <div>{{ indication.signer_name }}</div>
                      <template v-if="!modifiable">
                        <button
                          type="button mt-3"
                          v-if="indication.document_link"
                          @click="
                            openUrl({ fileName: indication.document_link })
                          "
                          :class="`py-2 px-3 ml-2 rounded-lg font-medium outline-none focus:outline-none border border-solid border-blue-300 text-blue-800 ${
                            indication.sign
                              ? 'bg-blue-50 hover:bg-blue-200'
                              : 'opacity-50 cursor-not-allowed'
                          } transition-all duration-200`"
                          :disabled="!indication.sign"
                        >
                          <i class="el-icon-printer" />
                          <span class="ml-1">{{ $t("In") }}</span>
                        </button>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="type === 1 && tableData.length && hasResult"
              class="col-md-7 mt-3"
            >
              <div class="p-3 border h-full hodo-print">
                <div class="row text-center mb-3">
                  <div
                    class="fs-24 font-bold"
                    style="text-transform: uppercase"
                  >
                    Kết quả xét nghiệm
                  </div>
                </div>

                <!-- Bảng kết quả xét nghiệm -->
                <el-table
                  :data="tableData"
                  :span-method="arraySpanMethod"
                  border
                  style="width: 100%"
                  cell-class-name="text-black"
                >
                  <el-table-column
                    label="Xét nghiệm"
                    min-width="250"
                    header-align="center"
                    align="left"
                  >
                    <template slot-scope="scope">
                      <span :class="scope.row.is_full_row ? 'font-bold' : ''">{{
                        scope.row.test_name
                      }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="Kết quả" header-align="center">
                    <template slot-scope="scope">
                      <span
                        class="block text-center"
                        :class="{ 'font-bold': scope.row.is_alert }"
                      >
                        <span
                          :style="
                            scope.row.low_high === 'H'
                              ? 'color: red !important'
                              : scope.row.low_high === 'L'
                              ? 'color: blue !important'
                              : ''
                          "
                        >
                          {{ scope.row.result }}
                        </span>
                        <span
                          class="ml-1"
                          :style="
                            scope.row.low_high === 'H'
                              ? 'color: red !important'
                              : scope.row.low_high === 'L'
                              ? 'color: blue !important'
                              : ''
                          "
                          v-if="scope.row.low_high !== ''"
                          >({{ scope.row.low_high }})</span
                        >
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="unit"
                    label="Đơn vị"
                    header-align="center"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="normal_rate"
                    label="Bình thường"
                    header-align="center"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="device"
                    label="Máy XN"
                    header-align="center"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="note"
                    label="Ghi chú"
                    header-align="center"
                    align="left"
                  ></el-table-column>
                </el-table>
                <!-- End of Bảng kết quả xét nghiệm -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- <div v-if="indication">
      <TempIndicationUltrasound
        :isXQuang="isXQuang"
        :save_pdf="save_pdf"
        :formData="formDataTemp"
      />
    </div> -->
  </div>
</template>

<script>
// import EditorInput from "@/components/EditorInput.vue";
import appUtils from '@/utils/appUtils'
import mdtUtils from '@/utils/mdtUtils'
import uploadIndicationFile from '@/utils/uploadIndicationFile'
import { INDICATION_TYPES } from '@/utils/constants'
import { isMobile, isTablet } from 'mobile-device-detect'

// // import TiptapEditor from "@/components/Editors/Tiptap";
// // import TempIndicationUltrasound from "../../../templates/ChiDinhSieuAm/TempIndicationUltrasound";

export default {
  name: 'ModalIndicationViewDetail',
  props: {
    patientVisit: {
      type: Object,
      required: true
    },
    showIndicationDetail: {
      type: Boolean,
      default: false
    },
    indicationDetailData: {
      type: Object,
      required: true
    },
    type: {
      type: Number,
      required: true
    }
  },
  // components: { TempIndicationUltrasound, EditorInput, TiptapEditor },
  data () {
    const defaultForm = {
      quantitativeIndex: '',
      description: '',
      conclusion: '',
      files: [],
      links: []
    }
    const generalInfoInitial = {
      diagnose: '',
      sample_taker_name: '',
      take_sample_at: '',
      sample_receiver_name: '',
      received_sample_at: '',
      patient_status: 0
    }

    return {
      isLoading: false,
      indication: null,
      conclusion: null,
      personData: null,
      examinationResults: null,
      appUtils,
      mdtUtils,
      defaultForm,
      form: this.defaultForm,
      editForm: false,
      uploadIndicationFile,
      s3Urls: [],
      INDICATION_TYPES,
      save_pdf: 0,
      templateForm: null,
      isSaveAndSign: false,
      tableData: [],
      generalInfoInitial,
      generalInfo: generalInfoInitial,
      showResult: false,
      isMobile,
      isTablet
    }
  },
  async created () {
    await this.getIndicationById(this.indicationDetailData?.id)
    await this.handleGetIndicationResult()
  },
  mounted () {
    this.$root.$once('onSavePDFToS3', this.savePdfToS3)
  },
  destroyed () {
    this.$root.$off('onSavePDFToS3')
  },

  watch: {
    // $refresh () {
    //   if (this.form.files?.length || this.form.links?.length) {
    //     this.$emit('refresh')
    //     this.getIndicationById(this.indication.id)
    //   }
    // },
    async indicationDetailData (data) {
      if (data) {
        this.indication = data
        await this.handleGetIndicationResult(data)
      }
    },
    indication (data) {
      if (data) {
        this.form = {
          ...this.form,
          files: data.files,
          links: data.links
        }
      }
    },
    s3Urls (data) {
      if (data) {
        /* eslint-disable no-unused-expressions */
        this.form.files?.forEach((file, index) => {
          this.form.files[index] = {
            ...file,
            s3Urls: this.s3Urls?.[index]
          }
        })
      }
    },
    examinationResults (data) {
      if (data) {
        this.examinationResults = data
      }
    },
    conclusion (data) {
      if (data) {
        this.form = {
          ...this.form,
          conclusion: data.conclusion,
          description: data.description
        }
      }
    },
    templateForm (data) {
      if (data) {
        if (this.conclusion?.description?.length) return

        this.form = {
          ...this.form,
          description: data.content || ''
        }
      }
    }
  },
  computed: {
    modifiable () {
      return !this.examinationResults || !!this.editForm
    },
    formDataTemp () {
      let sign = null
      if (this.indication?.sign) {
        sign = {
          sign_at: this.indication?.sign?.sign_at,
          signer_name: this.indication?.signer_name,
          uuid: this.indication?.sign?.uuid,
          isDocSign: false
        }
      }

      return {
        person: this.personData,
        indication: this.indication,
        sign
      }
    },
    isXQuang () {
      // indication category of X-Quang is 8686
      return this.indication?.indication_category_id === 8686
    },
    hasResult () {
      return this.tableData.some((item) => item?.result_at !== null)
    },
    isSyncPatientVisitTMatsouka () {
      if (!this.$globalClinic?.settings) return false
      const settings = JSON.parse(this.$globalClinic?.settings)
      const isSyncTMatsouka = settings?.his_connect === 'tmc'
      return isSyncTMatsouka
    }
  },
  methods: {
    onHideModal () {
      this.$emit('hideModal', true, true)
    },
    async getIndicationById (id) {
      if (!id) return

      const self = this

      try {
        self.isLoading = true
        await self.$rf
          .getRequest('AuthRequest')
          .getIndicationById(id)
          .then(async (res) => {
            const indicationFiles = await Promise.all(
              res.data.files?.map(async (item) => {
                if (item.type === 4) {
                  const resultUrl = await self.handleGetUrlSource(
                    item.file_name
                  )
                  return {
                    ...item,
                    resultUrl
                  }
                } else {
                  return item
                }
              })
            )

            self.indication = { ...res.data, files: indicationFiles }
            self.personData = res.data.indication_order?.person || null
            self.examinationResults = res.data.examination_results || null
            self.conclusion = res.data.examination_results?.conclusion || null
            const formatDescription = appUtils.formatImageBase64TiptapEditor(
              self.conclusion?.description
            )
            if (formatDescription) {
              self.conclusion.description = formatDescription
            }

            // await this.getIndicationTempByCateId(
            //   self.indication?.indication_category_id
            // )
          })

        const s3UrlsRaw = await Promise.all(
          self.indication?.files?.map(async (file, index) => {
            const res = await self.downloadFile({
              name: file.file_name,
              open: false
            })
            return res
          })
        )

        this.s3Urls = s3UrlsRaw
      } catch (error) {
        console.log(error)
        self.isLoading = false
      } finally {
        self.isLoading = false
      }
    },
    // async getIndicationTempByCateId (indicationId) {
    //   if (!indicationId) return

    //   const self = this

    //   try {
    //     self.isLoading = true

    //     const res = await self.$rf
    //       .getRequest('AuthRequest')
    //       .getIndicationTemplateByCateId(indicationId)

    //     if (res?.data) {
    //       this.templateForm = res.data
    //     }
    //   } catch (error) {
    //     console.log(error)
    //     self.isLoading = false
    //   }
    // },
    isImage (file) {
      if (!file) return false
      if (file?.url && /(getimagefile)/.test(file?.url)) {
        return true
      }

      if (file instanceof File) {
        return !!file.type.match('image.*')
      }

      const extensions = ['jpg', 'jpeg', 'png', 'tiff', 'bmp', 'svg']
      const extVal = file?.file_name?.split('.').pop().toLowerCase()

      return extensions.includes(extVal)
    },
    getExtensionOfFile (filename) {
      if (!filename) return
      return filename?.split('.').pop().toLowerCase()
    },
    getImageURL (path) {
      return appUtils.getImageURL(path)
    },
    getLangCode () {
      return appUtils.getLangCode(appUtils.getLangId(window.$lang))
    },
    getHMDMYVN (date) {
      if (!date) return
      const string = this.moment(date).locale(window.$lang)
      if (window.$lang === this.getLangCode()) {
        const dt = this.moment(date)
        const hm = dt.clone().format('HH:mm')
        const d = dt.clone().format('DD')
        const m = dt.clone().format('MM')
        const y = dt.clone().format('YYYY')
        return hm + ', ngày ' + d + ' tháng ' + m + ' năm ' + y
      } else {
        return string.format('LLL')
      }
    },
    onToggleModalUploadFileIndication (show) {
      window.$('#modalUploadFileIndication').modal(show ? 'show' : 'hide')
    },
    onSelectedFile (e) {
      const files = e.target.files
      if (files) {
        this.form.files = [...this.form.files, ...files]

        // const fileArr = Array.from(files)

        // fileArr.forEach(file => {
        //   if (self.isImage(file)) {
        //     self.encodeImageFileAsURL(file)
        //   }
        // })

        this.onSubmitFiles()
      }
    },
    onHandleCreateObjectURL (file) {
      if (!file) return ''
      if (file instanceof File) {
        return URL.createObjectURL(file)
      }
      return file?.s3Urls
    },
    uploadFiles () {
      this.$refs.inputFileIndication.click()
      this.$refs.inputFileIndication.value = null
    },
    onCreateANewLink () {
      this.form.links = [
        ...this.form.links,
        {
          name: '',
          url: '',
          type: 2
        }
      ]
    },
    onDeleteIndicationAttachWithType ({ id, index, type }) {
      this.$confirm(
        this.$t(
          `Bạn chắc chắn muốn xóa ${type === 1 ? 'tệp' : 'liên kết'} này?`
        ),
        this.$t('Cảnh báo')
      )
        .then(async (_) => {
          await this.deleteIndicationAttach(id)
          if (type === 1) {
            this.form.files.splice(index, 1)
          } else {
            this.form.links.splice(index, 1)
          }
        })
        .catch((_) => {})
    },
    async deleteIndicationAttach (id) {
      if (!id) return
      try {
        let result = null
        await this.$rf
          .getRequest('DoctorRequest')
          .deleteIndicationAttach(id)
          .then((res) => {
            result = res
            this.$message({
              message: this.$t('Xóa tệp thành công'),
              type: 'success'
            })
          })
          .catch((error) => {
            console.log(error)
            this.$message({
              message: this.$t('Xóa tệp thất bại'),
              type: 'error'
            })
          })

        return result
      } catch (error) {
        console.log(error)
      }
    },

    setParamsExaminationResult (params) {
      const paramsData = {
        indication_id: this.indication.id,
        ...params
      }

      delete paramsData.quantitativeIndex
      delete paramsData.files
      delete paramsData.links

      return paramsData
    },

    openLink (link) {
      link && window.open(link, '_blank', 'noreferrer')
    },
    async downloadFile ({ name, open = true }) {
      if (!name) return
      const self = this
      const params = {
        fileName: name
      }
      let result = null
      const header = {
        'x-api-key': '4be5160e89e681cede9a80ae6db6af7c'
      }
      await self.$rf
        .getRequest('AuthRequest')
        .getLinkDownloadAwsRc(params, header, false)
        .then((r) => {
          if (r?.data?.data && open) {
            window.open(r.data.data)
          } else {
            result = r.data.data
          }
        })
        .catch((e) => {
          self.$toast.open({
            message: this.$t(
              'File không tồn tại hoặc gặp lỗi trong quá trình tải xuống'
            ),
            type: 'error'
          })
          console.error(e)
        })

      return result
    },
    openFile (file) {
      if (file.type === 1) {
        this.downloadFile(file.file_name)
      } else {
        this.openLink(file.url)
      }
    },
    openWindow (url) {
      this.openLink(url)
    },
    async openUrl ({ fileName }) {
      if (!fileName) {
        this.$toast.open({
          message: this.$t('Không tìm thấy bản in'),
          type: 'error'
        })
        return
      }
      window.open(fileName)

      // try {
      //   this.isLoading = true
      //   const res = await this.$rf.getRequest('DoctorRequest').getEmrDocument({
      //     fileName
      //   })
      //   if (res.data) {
      //     if (print) {
      //       const printWindow = window.open(res.data?.documentLink)
      //       printWindow.print()

      //       printWindow.onafterprint = function () {
      //         printWindow.close()
      //       }
      //     } else {
      //       window.open(res.data?.documentLink)
      //     }
      //   }
      //   this.isLoading = false
      // } catch (error) {
      //   console.log(error)
      //   this.isLoading = false
      // }
    },

    encodeImageFileAsURL (url) {
      const self = this

      const el = document.createElement('div')
      const parser = new DOMParser()
      const descriptionEl = parser.parseFromString(
        self.form.description,
        'text/xml'
      )
      el.appendChild(descriptionEl.documentElement)

      const imageString = `<img src="${url}" />`
      const imageEl = parser.parseFromString(imageString, 'text/xml')
      el.appendChild(imageEl.documentElement)

      self.form.description = el.outerHTML
    },

    onSelectedFileDicom (e) {
      const files = e.target.files
      if (files) {
        this.form.files = [...this.form.files, ...files]

        this.onUploadPac(files, 3)
      }
    },

    handleGetPacInfo (file) {
      let data = {
        type: 'primary',
        label: 'Đang xử lý'
      }
      if (file?.pacs?.status === 1) {
        data = {
          type: 'primary',
          label: 'Đang xử lý'
        }
      } else if (file?.pacs?.status === 2) {
        data = {
          type: 'success',
          label: 'Đã xử lý'
        }
      }

      return data
    },
    async openPac (pacInfo) {
      if (!pacInfo?.id || pacInfo?.status !== 2 || !pacInfo?.accession) return

      const self = this
      const params = {
        accession: pacInfo.accession
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .getUrlPac(params)
        .then((r) => {
          if (r.data) {
            window.open(r.data)
          }
        })
    },
    async handleGetUrlSource (url) {
      if (url.includes('resources/patientVisits')) {
        const resultUrl = await uploadIndicationFile.getLinkVideoAWS(url)

        return resultUrl
      } else {
        return url
      }
    },
    arraySpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (row.is_full_row) {
        if (columnIndex === 0) {
          return [1, 6]
        } else return [0, 0]
      }
    },
    async handleGetIndicationResult (data) {
      this.tableData = []
      this.generalInfo = {
        ...this.generalInfoInitial
      }
      const id = data?.id || this.indicationDetailData?.id

      if (!id) return

      try {
        const params = {
          indication_ids: id
        }
        const res = await this.$rf
          .getRequest('AuthRequest')
          .getLisIndicationsResultData(params)

        if (res?.data) {
          res.data.Data.forEach((exam_group) => {
            this.tableData = [
              ...this.tableData,
              {
                ...exam_group,
                test_name: exam_group.name || exam_group.description,
                is_full_row: true
              }
            ]
            const resultArr = exam_group?.examination_result_values.map(
              (exam) => {
                const lowHigh =
                  Number(exam.lower) !== Number(exam.upper)
                    ? !isNaN(Number(exam.value))
                      ? Number(exam.value) > Number(exam.upper)
                        ? 'H'
                        : Number(exam.value) < Number(exam.lower)
                          ? 'L'
                          : ''
                      : ''
                    : ''

                return {
                  test_name: exam.name,
                  result: exam.value || '-',
                  low_high: lowHigh,
                  unit: exam.unit || '-',
                  // normal_rate:
                  //   Number(exam.lower) !== Number(exam.upper)
                  //     ? `${exam.lower} - ${exam.upper}`
                  //     : "-",
                  device: exam_group.device || '-',
                  note: exam?.note || '-',
                  is_alert: lowHigh !== ''
                }
              }
            )
            this.tableData = [...this.tableData, ...resultArr]
          })
          this.generalInfo =
            {
              ...res.data.meta,
              take_sample_at: appUtils.isDate(res.data.meta?.take_sample_at)
                ? appUtils.formatDateTimeFull(res.data.meta?.take_sample_at)
                : '',
              received_sample_at: appUtils.isDate(
                res.data.meta?.received_sample_at
              )
                ? appUtils.formatDateTimeFull(res.data.meta?.received_sample_at)
                : ''
            } || this.generalInfo
        }
      } catch (err) {
        console.log(err)
        this.$message({
          message: 'Lấy dữ liệu xét nghiệm không thành công',
          type: 'warning'
        })
      }
    },
    handleToggleShowResult () {
      this.showResult = !this.showResult
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-table {
  margin-top: 10px;
  border-top: 1px solid #000 !important;

  &__header-wrapper {
    text-transform: uppercase;

    th {
      padding: 8px 0px;
      background-color: #f0f0f0 !important;
    }
  }

  &__row {
    // .el-table__cell:last-of-type {
    //   border-right: none !important;
    // }
  }

  &__cell {
    color: black;
    border-right: 1px solid #000 !important;
    border-bottom: 1px solid #000 !important;
  }

  &--border {
    border-bottom: 1px solid #000 !important;
  }
}

::v-deep {
  .hodo-ckeditor {
    &__default {
      .ck-editor__editable {
        min-height: 70px;
      }
    }

    &__big {
      .ck-editor__editable {
        min-height: 350px;
      }
    }
  }

  .hodo-table {
    img {
      max-width: 800px;
      object-fit: cover;
      object-position: center;
    }
  }

  .hodo-table table {
    width: 100% !important;
    border: 1px solid #dbdde0 !important;
    margin-top: 1rem !important;
  }

  .hodo-table table thead th {
    text-transform: uppercase !important;
  }

  .hodo-table table th,
  .hodo-table table td {
    padding: 8px !important;
    border: 1px solid #dbdde0 !important;
  }
}

.selected-file-icon {
  width: 100%;
  height: 100%;
  background-size: contain;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
