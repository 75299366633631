<template>
  <base-temp
    id="prescriptionTemp"
    :title="$t('Đơn Thuốc')"
    :form-data="formData"
    :save_pdf="save_pdf"
    :check-lang="checkLang"
  >
    <div class="mb-8" v-if="form">
      <div class="mb-3">
        <div class="font-bold mb-2 fs-18">{{ $t("Chẩn đoán") }}</div>
        <div
          class="hodo-table fs-18 content-size"
          v-if="form.icd_name"
          v-html="form.icd_name"
        ></div>
      </div>
      <div class="mb-3">
        <div class="font-bold mb-2 fs-18">{{ $t("Chỉ định thuốc") }}</div>
        <div
          v-for="(item, index) in form.prescription.person_medicines"
          :key="item.id"
          class="mb-1"
        >
          <div class="w-two-thirds align-top inline-block">
            <div class="font-bold fs-18">
              {{ `${index + 1}. ${handleGetMedicineName(item)}` }}
            </div>
            <div
              class="italic fs-18"
              style="margin-left: 14px"
              v-if="item.doctor_note"
            >
              {{ item.doctor_note }}
            </div>
            <div
              class="italic fs-18"
              style="margin-left: 14px"
              v-if="item.side_effect"
            >
              Ghi chú: {{ item.side_effect }}
            </div>
          </div>
          <div class="w-one-third align-top inline-block">
            <div class="w-half font-bold inline-block fs-18">SL</div>
            <div class="w-half font-bold inline-block fs-18">Đơn vị</div>
            <div class="w-half inline-block fs-18">
              {{ convertNumber(item.amount) }}
            </div>
            <div class="w-half inline-block fs-18">{{ item.amount_unit }}</div>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <div class="font-bold mb-2 fs-18">{{ $t("Lời dặn của bác sĩ") }}</div>
        <div
          class="hodo-table fs-18 content-size"
          v-if="form.prescription.description"
          v-html="form.prescription.description"
        ></div>
      </div>
    </div>
  </base-temp>
</template>

<script>
import appUtils from '@/utils/appUtils'
import mdtUtils from '@/utils/mdtUtils'
import { mixinPrint } from '@/utils/mixinPrint'
import BaseTemp from './BaseTemp.vue'

export default {
  name: 'TempPrescription',
  mixins: [mixinPrint],
  props: {
    formData: {
      type: Object,
      required: true
    },
    save_pdf: {
      type: Number,
      required: true
    },
    checkLang: {
      type: Number
    }
  },
  components: {
    BaseTemp
  },
  data () {
    return {
      loading: false,
      form: null,
      appUtils,
      mdtUtils
    }
  },
  mounted () {
    if (this.formData) {
      this.form = this.formData
    }
  },
  computed: {},
  watch: {
    formData (data) {
      this.form = data
    }
  },
  methods: {
    convertNumber (value) {
      if (!value) return
      const arrayCharacter = String(value).split('.')
      const afterDot = arrayCharacter[1]?.substring(0, 1)
      if (Number(afterDot) > 0) {
        return [arrayCharacter[0], afterDot].join('.')
      } else {
        return arrayCharacter[0]
      }
    },
    handleGetMedicineName (medicine) {
      const medicineName = medicine?.name || medicine?.product_info?.name || ''
      const activeIngredient =
        medicine?.active_ingredient ||
        medicine?.product_info?.active_ingredient_info?.name ||
        ''
      const quality =
        medicine?.quality || medicine?.product_info?.quality || ''

      return `${medicineName} ${quality} ${
        activeIngredient ? `(${activeIngredient})` : ''
      }`
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  body,
  html {
    font-family: "Times New Roman", Tinos, Times, serif !important;
  }

  p {
    margin-top: 0;
  }

  .relative {
    position: relative;
  }

  .absolute {
    position: absolute;
  }

  .top-0 {
    top: 0;
  }

  .left-0 {
    left: 0;
  }

  .block {
    display: block;
  }

  .inline-block {
    display: inline-block !important;
  }

  .flex {
    display: flex;
  }

  .flex-col {
    flex-direction: column;
  }

  .align-top {
    vertical-align: top;
  }

  .text-center {
    text-align: center;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .justify-center {
    justify-content: center;
  }

  .justify-between {
    justify-content: space-between;
  }

  .items-start {
    align-items: flex-start;
  }

  .items-center {
    align-items: center;
  }

  .w-full {
    width: 100% !important;
  }

  .w-half {
    width: 50% !important;
  }

  .w-one-third {
    width: 33.333% !important;
  }

  .w-two-thirds {
    width: 66.667% !important;
  }

  .font-bold {
    font-weight: 700;
  }

  .text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .pl-12 {
    padding-left: 3rem;
  }

  .pl-16 {
    padding-left: 4rem;
  }

  .pr-12 {
    padding-right: 3rem;
  }

  .pr-16 {
    padding-right: 4rem;
  }

  .mt-6 {
    margin-top: 1.5rem;
  }

  .mt-8 {
    margin-top: 2rem;
  }

  .mt-12 {
    margin-top: 3rem;
  }

  .mt-16 {
    margin-top: 4rem;
  }

  .mt-24 {
    margin-top: 6rem;
  }

  .mr-1 {
    margin-right: 0.25rem;
  }

  .mb-0 {
    margin-bottom: 0;
  }

  .mb-1 {
    margin-bottom: 0.25rem;
  }

  .mb-2 {
    margin-bottom: 0.5rem;
  }

  .mb-3 {
    margin-bottom: 0.75rem;
  }

  .mb-6 {
    margin-bottom: 1.5rem;
  }

  .mb-8 {
    margin-bottom: 2rem;
  }
  .hodo-table figure {
    margin: 0 0 1rem !important;
  }
  .hodo-table table {
    border-collapse: collapse !important;
    width: 100% !important;
    border: 1px solid #dbdde0 !important;
    margin-top: 1rem !important;
  }
  .hodo-table table thead th {
    text-transform: uppercase !important;
  }
  .hodo-table table th,
  .hodo-table table td {
    padding: 8px !important;
    border: 1px solid #dbdde0 !important;
  }
}
</style>
