<template>
  <div>
    <div class="flex flex-col md:flex-row gap-4">
      <div class>
        <div class="d-flex">
          <label for class="fs-20 fw-500 txt-pri mb-2 whitespace-nowrap">
            <svg
              class="mr-1"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 5.32998C13.01 3.97998 14.63 3.09998 16.44 3.09998C19.51 3.09998 22 5.59998 22 8.68998C22 9.59377 21.8904 10.4457 21.6931 11.25H17.4684C17.3942 11.25 17.3238 11.217 17.2763 11.16L16.055 9.69448C15.2471 8.72498 13.7098 8.9034 13.1454 10.0322L11.6266 13.0698C11.5393 13.2444 11.2945 13.256 11.191 13.0905L9.92975 11.0725C9.60996 10.5608 9.04913 10.25 8.44575 10.25H2.11193C2.03885 9.74778 2 9.22808 2 8.68998C2 5.59998 4.49 3.09998 7.56 3.09998C9.37 3.09998 10.99 3.97998 12 5.32998ZM2.44243 11.75C2.46735 11.8339 2.49321 11.9172 2.52 12C4.1 17 8.97 19.99 11.38 20.81C11.72 20.93 12.28 20.93 12.62 20.81C14.907 20.0318 19.4092 17.2996 21.2131 12.75H17.4684C16.949 12.75 16.4565 12.5193 16.124 12.1203L14.9027 10.6548C14.7873 10.5163 14.5677 10.5417 14.487 10.703L12.9682 13.7406C12.3571 14.9629 10.6433 15.0444 9.91898 13.8855L8.65775 11.8675C8.61207 11.7944 8.53195 11.75 8.44575 11.75H2.44243Z"
                fill="#20419B"
              />
            </svg>
            {{ $t(" Mã bệnh (ICD 10)") }}
          </label>
        </div>
        <el-input v-model="dataIcd.code" disabled></el-input>
      </div>
      <div class="flex-1">
        <div class="d-flex">
          <label for class="fs-20 fw-500 txt-pri mb-2">
            {{
            $t("Tên bệnh")
            }}
          </label>
        </div>
        <el-input v-model="dataIcd.name" disabled class="cs-mobile-width w-full"></el-input>
      </div>
    </div>
  </div>
</template>

<script>
// import _ from 'lodash'

export default {
  name: 'SelectDiseaseICD10',
  props: { dataIcd: Object },
  data () {
    return {
      diseasesCode: '',
      diseasesName: '',
      diseasesList: []
    }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        if (this.dataIcd?.code || this.dataIcd?.name) {
          this.diseasesCode = this.dataIcd.code
          this.diseasesName = this.dataIcd.name
        }
      }
    }
  },
  async created () {
    // await this.getIcd10()
    if (this.dataIcd?.code || this.dataIcd?.name) {
      this.diseasesCode = this.dataIcd.code
      this.diseasesName = this.dataIcd.name
    }
  },
  methods: {
    // async getIcd10 (code, name) {
    //   const p = {
    //     limit: 1000,
    //     code,
    //     name
    //   }
    //   const res = await this.$rf.getRequest('AuthRequest').getIcd10(p)
    //   this.diseasesList = res?.data?.data || []
    // },
    setName (value) {
      // const item = _.find(this.diseasesList, ['code', value])
      // this.diseasesName = item?.name
      // this.$emit('onChange', item)
    },
    setCode (value) {
      // const item = _.find(this.diseasesList, ['name', value])
      // this.diseasesCode = item?.code
      // this.$emit('onChange', item)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
