import Vue from 'vue'

const prototype = Vue.prototype
const bucketName = 'hodo-resource'
const aws = window.AWS
const prefix = 'resources/patientVisits/'
const region = 'ap-southeast-1'
const IdentityPoolId = 'ap-southeast-1:ff53cfbf-2595-4ade-83b8-43e3ad25f279'

aws.config.region = region // 1. Enter your region
aws.config.credentials = new aws.CognitoIdentityCredentials({
  IdentityPoolId: IdentityPoolId // 2. Enter your identity pool
})
aws.config.credentials.get(function (err) {
  if (err) alert(err)
  // console.log(AWS.config.credentials);
})

const uploadConsultationFile = {
  fileUploadHanlder (patientId, files, type, consultationCommentId = null, nameFiles = null, examinationResultId = null) {
    if (!files || !files.length) return
    files.forEach((file, i) => {
      if (file) {
        const options = {
          patientId,
          file,
          type
        }
        if (nameFiles) {
          options.nameFiles = nameFiles[i]
        }
        if (consultationCommentId) {
          options.consultationCommentId = consultationCommentId
        }
        if (examinationResultId) {
          options.examinationResultId = examinationResultId
        }
        const newPrefix = prefix + patientId + '/' + (consultationCommentId ? 'comment/' + consultationCommentId + '/' : '') + (examinationResultId ? 'examination-result/' + examinationResultId + '/' : '') + (window.moment().unix()) + i + '_' + file.name
        this.s3ResourcesUploader(newPrefix, options)
      }
    })
  },
  async s3ResourcesUploader (path, options) {
    const self = this
    let bucket = null
    bucket = new aws.S3({
      params: {
        Bucket: bucketName
      }
    })

    if (options.file) {
      const file = options.file
      const objKey = path
      const params = {
        Key: objKey,
        ContentType: file.type,
        Body: file
        // ACL: 'public-read'
      }
      //   this.progress.show = true
      //   this.progress.title = 'Đang tải lên Ảnh DICOM'
      //   this.progress.name = file.name
      //   this.$uploading = true
      const uploadingInfo = {
        id: this.makeid() + window.moment().unix(),
        name: options.nameFiles?.description ? options.nameFiles?.description : file.name,
        value: 0,
        status: 0
      }
      prototype.$multiUploading.push(uploadingInfo)
      bucket.upload(params).on('httpUploadProgress', function (evt) {
        var uploaded = Math.round(evt.loaded / evt.total * 100)
        uploadingInfo.value = uploaded
        var foundIndex = prototype.$multiUploading.findIndex(x => x.id === uploadingInfo.id)
        prototype.$multiUploading[foundIndex] = uploadingInfo
      }).send(function (err, data) {
        var foundIndex = prototype.$multiUploading.findIndex(x => x.id === uploadingInfo.id)
        if (err) {
          uploadingInfo.status = 2
          // an error occurred, handle the error
          console.log('upload failed', err, err.stack)
          prototype.$multiUploading[foundIndex] = uploadingInfo
          setTimeout(() => {
            if (foundIndex > -1) prototype.$multiUploading.splice(foundIndex, 1)
          }, 800)
          return
        }
        console.log('options', options)
        const params = {
          patient_visit_id: options.patientId,
          type: options.type,
          name: options.nameFiles?.description ? options.nameFiles?.description : options.file.name,
          file_name: data.Key
        }
        if (options.consultationCommentId) {
          params.consultationCommentId = options.consultationCommentId
        }
        if (options.examinationResultId) {
          params.examinationResultId = options.examinationResultId
        }
        uploadingInfo.status = 1
        prototype.$multiUploading[foundIndex] = uploadingInfo
        setTimeout(() => {
          if (foundIndex > -1) prototype.$multiUploading.splice(foundIndex, 1)
        }, 800)
        setTimeout(() => {
          if (!prototype.$multiUploading?.length) prototype.$refresh++
        }, 900)
        self.createConsultationAttach(params)
      })
    } else {
      return false
    }
  },
  async createConsultationAttach (options) {
    const r = await prototype.$rf.getRequest('AuthRequest').uploadPatientFiles(options).then((r) => {
      // prototype.$emit('refresh')
      return r.data
    }).catch((error) => {
      console.log('error', error)
    })
    return r
  },
  makeid (length) {
    var result = ''
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength))
    }
    return result
  }
}

export default uploadConsultationFile
