<template>
  <div class="rounded-lg p-3 shadow-2xl bg-white mb-2">
    <div class="flex items-center">
      <div class="fs-20 fw-500">{{ name }}</div>
    </div>

    <div class="mt-2">
      <el-collapse
        class="hodo-el-collapse"
        v-model="activeCollapseItems"
        @change="handleChangeCollapse"
      >
        <template v-for="(item, index) in newIndicationOrders">
          <el-collapse-item :name="`collapse-${index}`" :key="item.id">
            <template slot="title">
              <div
                @click="onHandleViewIndication(item)"
                @click.stop
                class="inline-block"
                role="button"
              >
                <div
                  class="flex items-center font-medium txt-pri hover:text-blue-600 transition-all duration-200"
                >
                  <span class="mr-1">{{
                    `${$t(item.name)}${
                      item.indication.length
                        ? ` (${item.indication.length})`
                        : ""
                    }`
                  }}</span>
                </div>
                <div class="text-xs italic font-normal mt-1">
                  <span class="mr-1">Cập nhật lần cuối:</span>
                  <span>{{ getUpdatedAt(item) }}</span>
                </div>
              </div>
            </template>
            <div>
              <div
                v-for="(indication, index) in item.indication"
                :key="indication.id"
              >
                <div
                  :class="
                    index < item.indication.length
                      ? 'border-b border-t-0 border-r-0 border-l-0 border-solid border-gray-200'
                      : ''
                  "
                  class="group flex justify-between hover:bg-gray-100 transition-all duration-200 px-2 py-1"
                  role="button"
                  @click="onHandleViewIndicationResult(indication)"
                >
                  <div class="relative transition-all duration-200">
                    <span class="txt-pri">{{ $t(indication.name) }}</span>
                  </div>
                  <div
                    class="italic"
                    :set="(status = hasExaminationResults(indication))"
                  >
                    <span :class="status.class">{{ status.text }}</span>
                  </div>
                </div>
                <div
                  class="border border-gray-200 p-2"
                  v-if="indication.files.length || indication.links.length"
                >
                  <div
                    v-if="indication.files.length"
                    class="flex flex-wrap items-center -mx-1"
                  >
                    <div
                      class="px-1"
                      v-for="file in indication.files"
                      :key="file.id"
                    >
                      <div v-if="file.pac_id">
                        <div
                          class="p-3 cursor-pointer radius-10 bg-gray-50 item-wraper"
                          :class="file.status === 2 ? 'c-pointer' : ''"
                          :set="(status = getStatus(file.pacs.status))"
                        >
                          <a
                            :href="file.resultUrl"
                            target="_blank"
                            class="item-doc radius-10"
                          >
                            <div
                              class="d-flex justify-content-center align-items-center py-3"
                            >
                              <svg
                                width="97"
                                height="78"
                                viewBox="0 0 97 78"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M97 21.9286C97 17.5103 93.4183 13.9286 89 13.9286H44.5841C42.526 13.9286 40.5471 13.1354 39.0588 11.7139L29.1126 2.21466C27.6242 0.793185 25.6453 0 23.5872 0H8.00001C3.58173 0 0 3.58172 0 8V70C0 74.4183 3.58173 78 8 78H89C93.4183 78 97 74.4183 97 70V21.9286Z"
                                  fill="#20419B"
                                />
                                <rect
                                  x="13"
                                  y="5"
                                  width="77"
                                  height="60"
                                  rx="7"
                                  fill="#F1BD19"
                                />
                                <path
                                  d="M0 21.9286C0 17.5103 3.58172 13.9286 8 13.9286H52.4159C54.474 13.9286 56.4529 13.1354 57.9412 11.7139L67.8874 2.21466C69.3758 0.793185 71.3547 0 73.4128 0H89C93.4183 0 97 3.58172 97 8V70C97 74.4183 93.4183 78 89 78H8C3.58172 78 0 74.4183 0 70V21.9286Z"
                                  fill="#476DD6"
                                />
                                <rect
                                  x="74"
                                  width="9"
                                  height="9"
                                  fill="white"
                                />
                                <rect
                                  x="74"
                                  y="18"
                                  width="9"
                                  height="9"
                                  fill="white"
                                />
                                <rect
                                  x="74"
                                  y="36"
                                  width="9"
                                  height="9"
                                  fill="white"
                                />
                                <rect
                                  x="83"
                                  y="9"
                                  width="9"
                                  height="9"
                                  fill="white"
                                />
                                <rect
                                  x="83"
                                  y="27"
                                  width="9"
                                  height="9"
                                  fill="white"
                                />
                                <rect
                                  x="83"
                                  y="45"
                                  width="9"
                                  height="9"
                                  fill="white"
                                />
                                <rect
                                  x="78"
                                  y="54"
                                  width="9"
                                  height="15"
                                  rx="1"
                                  fill="#F1BD19"
                                />
                                <rect
                                  x="80"
                                  y="65"
                                  width="5"
                                  height="2"
                                  rx="1"
                                  fill="#476DD6"
                                />
                              </svg>
                            </div>
                            <div class="bottom-item pt-2">
                              <p class="mb-0 fs-14 max-line-1 txt-gray-blur">
                                {{ file.name || "Ảnh tài liệu " + i }}
                              </p>
                              <p class="mb-0 fs-14 max-line-1 txt-gray-blur">
                                {{ formatDMY(file.created_at) }}
                              </p>
                              <div class="flex justify-between items-center">
                                <p
                                  class="mb-0 fs-14 max-line-1"
                                  :class="status ? status.class : ''"
                                >
                                  {{ status.name || "" }}
                                </p>
                                <div
                                  class="cursor-pointer"
                                  @click.stop="downloadPacs(file)"
                                >
                                  <svg
                                    width="25"
                                    height="24"
                                    viewBox="0 0 25 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M19.0775 20H5.0775C4.81228 20 4.55793 20.1054 4.37039 20.2929C4.18286 20.4804 4.0775 20.7348 4.0775 21C4.0775 21.2652 4.18286 21.5196 4.37039 21.7071C4.55793 21.8946 4.81228 22 5.0775 22H19.0775C19.3427 22 19.5971 21.8946 19.7846 21.7071C19.9721 21.5196 20.0775 21.2652 20.0775 21C20.0775 20.7348 19.9721 20.4804 19.7846 20.2929C19.5971 20.1054 19.3427 20 19.0775 20ZM11.3675 17.71C11.4626 17.801 11.5747 17.8724 11.6975 17.92C11.8172 17.9729 11.9466 18.0002 12.0775 18.0002C12.2084 18.0002 12.3378 17.9729 12.4575 17.92C12.5803 17.8724 12.6924 17.801 12.7875 17.71L16.7875 13.71C16.9758 13.5217 17.0816 13.2663 17.0816 13C17.0816 12.7337 16.9758 12.4783 16.7875 12.29C16.5992 12.1017 16.3438 11.9959 16.0775 11.9959C15.8112 11.9959 15.5558 12.1017 15.3675 12.29L13.0775 14.59V3C13.0775 2.73478 12.9721 2.48043 12.7846 2.29289C12.5971 2.10536 12.3427 2 12.0775 2C11.8123 2 11.5579 2.10536 11.3704 2.29289C11.1829 2.48043 11.0775 2.73478 11.0775 3V14.59L8.7875 12.29C8.69426 12.1968 8.58357 12.1228 8.46175 12.0723C8.33993 12.0219 8.20936 11.9959 8.0775 11.9959C7.94564 11.9959 7.81507 12.0219 7.69325 12.0723C7.57143 12.1228 7.46074 12.1968 7.3675 12.29C7.27426 12.3832 7.2003 12.4939 7.14984 12.6158C7.09938 12.7376 7.07341 12.8681 7.07341 13C7.07341 13.1319 7.09938 13.2624 7.14984 13.3842C7.2003 13.5061 7.27426 13.6168 7.3675 13.71L11.3675 17.71Z"
                                      fill="black"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </a>
                          <div
                            class="delete-btn p-0 radius-10 c-pointer"
                            v-if="file.status === 2"
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M21.25 9.14993C18.94 5.51993 15.56 3.42993 12 3.42993C10.22 3.42993 8.49 3.94993 6.91 4.91993C5.33 5.89993 3.91 7.32993 2.75 9.14993C1.75 10.7199 1.75 13.2699 2.75 14.8399C5.06 18.4799 8.44 20.5599 12 20.5599C13.78 20.5599 15.51 20.0399 17.09 19.0699C18.67 18.0899 20.09 16.6599 21.25 14.8399C22.25 13.2799 22.25 10.7199 21.25 9.14993ZM12 16.0399C9.76 16.0399 7.96 14.2299 7.96 11.9999C7.96 9.76993 9.76 7.95993 12 7.95993C14.24 7.95993 16.04 9.76993 16.04 11.9999C16.04 14.2299 14.24 16.0399 12 16.0399Z"
                                fill="#476DD6"
                              />
                              <path
                                d="M12.0004 9.13989C10.4304 9.13989 9.15039 10.4199 9.15039 11.9999C9.15039 13.5699 10.4304 14.8499 12.0004 14.8499C13.5704 14.8499 14.8604 13.5699 14.8604 11.9999C14.8604 10.4299 13.5704 9.13989 12.0004 9.13989Z"
                                fill="#476DD6"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div
                        v-else
                        class="flex w-8 h-8 cursor-pointer border rounded"
                        @click="
                          openLink(file.file_name || file.s3Url || file.url)
                        "
                      >
                        <img
                          v-if="isImage(file)"
                          class="m-auto object-cover w-full"
                          :src="file.file_name || file.s3Url || file.url"
                          :alt="file.name"
                        />
                        <span
                          v-else
                          class="m-auto text-xs font-bold uppercase whitespace-nowrap"
                          >{{ getExtensionOfFile(file.name) }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="indication.links.length"
                    :class="`flex flex-wrap items-center -mx-1 ${
                      indication.files.length
                        ? 'mt-2 pt-2 border-b-0 border-t border-r-0 border-l-0 border-solid border-gray-200'
                        : ''
                    }`"
                  >
                    <div
                      class="w-1/2 lg:w-1/3 text-sm px-1 whitespace-nowrap truncate"
                      v-for="link in indication.links"
                      :key="link.id"
                    >
                      <i class="el-icon-link mr-1" />
                      <span
                        class="text-blue-800 hover:text-blue-600 hover:underline transition-all duration-200 cursor-pointer"
                        @click="openLink(link.url)"
                        >{{ link.name || link.url }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-collapse-item>
        </template>
      </el-collapse>
    </div>

    <CUInficationOrderTemp
      v-if="showIndicationOrderDetail && indicationOrderDetail"
      :type="type"
      :indicationOrderData="indicationOrderDetail"
      :showIndicationOrderDetail="showIndicationOrderDetail"
      @hideModal="onHideModal"
      @openUpdateIndication="openUpdateIndication"
    />
    <ModalIndicationViewDetail
      v-if="showIndicationDetail"
      :patient-visit="patientVisit"
      :show-indication-detail="showIndicationDetail"
      :indication-detail-data="indicationDetail"
      :type="type"
      @hideModal="onHideModal"
      @reloadPage="onReloadPage"
    />
  </div>
</template>

<style scoped lang="scss">
::v-deep {
  .hodo-el-collapse {
    .el-collapse-item {
      &__header {
        height: auto;
        line-height: normal;
        padding: 8px 0;
      }
      &__wrap {
        border-bottom: none;
      }
      &__content {
        padding-bottom: 8px;
      }
    }
  }
}
</style>

<script>
import appUtils from '../../utils/appUtils'
import CUInficationOrderTemp from '../Indications/V2/CUInficationOrderTemp.vue'
import { INDICATION_STATUS } from '../../utils/constants'
import _ from 'lodash'
import moment from 'moment'
import downloadS3 from '../../utils/downloadS3'
import ModalIndicationViewDetail from './Indication/ModalIndicationViewDetail.vue'

const statuses = [
  { id: 1, name: 'Đang xử lý', class: 'text-warning' },
  { id: 2, name: 'Hoàn thành', class: 'text-success' },
  { id: 3, name: 'Không xử lý được tài liệu', class: 'text-danger' }
]

export default {
  name: 'IndicationTemp',
  props: {
    name: {
      type: String,
      required: true
    },
    type: {
      type: Number,
      required: true
    },
    consultation: {
      type: Object,
      required: true
    },
    patientVisit: {
      type: Object,
      required: true
    }
  },
  components: {
    CUInficationOrderTemp,
    ModalIndicationViewDetail
  },
  data () {
    return {
      appUtils,
      showIndicationModal: false,
      showIndicationOrderDetail: false,
      showIndicationDetail: false,
      consultationData: this.consultation || null,
      patientVisitData: this.patientVisit || null,
      indicationOrders: [],
      indicationOrderDetail: null,
      indicationDetail: {},
      INDICATION_STATUS,
      activeCollapseItems: ['collapse-0'],
      s3UrlReady: false,
      s3Urls: [],
      newIndicationOrders: [],
      statuses
    }
  },
  async created () {
    const self = this
    self.consultationData = self.consultation

    const _tempIndicationOrders = _.filter(
      self.consultationData.indication_order,
      { cate: self.type }
    )
    await self.getS3FilesData(_tempIndicationOrders)

    self.indicationOrders.map((item, index) => {
      self.activeCollapseItems.push(`collapse-${index}`)
    })
  },
  mounted () {},
  watch: {
    async s3UrlReady (status) {
      if (status) {
        const indicationOrders = await Promise.all(
          this.indicationOrders?.map(async (item) => {
            const indications = await Promise.all(
              item?.indication?.map(async (indicationItem) => {
                const files = await Promise.all(
                  indicationItem?.files?.map(async (file) => {
                    const resultUrl = await this.getUrl(file.pacs)
                    return {
                      ...file,
                      resultUrl
                    }
                  })
                )
                return {
                  ...indicationItem,
                  files
                }
              })
            )
            return {
              ...item,
              indication: indications
            }
          })
        )
        this.newIndicationOrders = indicationOrders
      } else {
        await self.getS3FilesData(this.indicationOrders)
      }
    },
    async consultation (data) {
      const self = this

      if (data) {
        self.consultationData = data

        const _tempIndicationOrders = _.filter(
          self.consultationData.indication_order,
          { cate: self.type }
        )

        await self.getS3FilesData(_tempIndicationOrders)

        self.indicationOrders.map((item, index) => {
          self.activeCollapseItems.push(`collapse-${index}`)
        })
      }
    },
    async patientVisit (data) {
      if (data) {
        this.patientVisitData = data
        await self.getS3FilesData(data.indication_order)
      } else {
        this.patientVisitData = null
      }
    },
    's3Urls.length' (count) {
      this.$forceUpdate()
    }
  },

  methods: {
    async getS3FilesData (_tempIndicationOrders) {
      const self = this

      self.s3UrlReady = false
      self.s3Urls = []

      try {
        if (!_tempIndicationOrders.length) return

        /* eslint-disable no-unused-expressions */
        const _tempNew = await Promise.all(
          _tempIndicationOrders?.map(async (io) => {
            /* eslint-disable no-unused-expressions */
            await io?.indication?.map(async (item) => {
              await item?.files?.map(async (file) => {
                const res = await self.downloadFile({
                  name: file.file_name,
                  open: false
                })
                self.s3Urls.push(res)
                file.s3Url = res
                return file
              })
              return item
            })
            return io
          })
        )
        self.indicationOrders = _tempNew
      } catch (error) {
        console.log(error)
        self.s3UrlReady = false
      } finally {
        self.s3UrlReady = true
      }
    },
    handleChangeCollapse (val) {
      // console.log(val)
    },
    getIndicationStatus (stt) {
      switch (stt) {
        case INDICATION_STATUS.CODE.PENDING:
          return {
            text: this.$t(''),
            class: 'text-yellow-600'
          }
        case INDICATION_STATUS.CODE.HAS_RESULT:
          return {
            text: this.$t('Đã có KQ'),
            class: 'text-green-700'
          }
        case INDICATION_STATUS.CODE.CS:
          return {
            text: this.$t('Có dấu hiệu bất thường'),
            class: 'text-red-600'
          }
        case INDICATION_STATUS.CODE.NCS:
          return {
            text: this.$t('Không có dấu hiệu bất thường'),
            class: 'text-blue-800'
          }

        default:
          break
      }
    },
    hasExaminationResults (data) {
      if (
        data.examination_results &&
        data.sign &&
        typeof data !== 'undefined'
      ) {
        return {
          text: this.$t('Xem KQ'),
          class: 'text-green-700'
        }
      } else {
        return {
          text: this.$t('Đang cập nhật'),
          class: 'text-yellow-600'
        }
      }
    },
    openCreateIndication () {
      this.indicationOrderDetail = null
      this.showIndicationModal = true
    },
    onHideModal () {
      this.showIndicationModal = false
      this.showIndicationOrderDetail = false
      this.showIndicationDetail = false
    },
    onRefresh () {
      this.onHideModal()
      this.$emit('refresh')
    },
    onReloadPage () {
      this.$emit('refresh')
    },
    onHandleViewIndication (item) {
      this.indicationOrderDetail = item
      this.showIndicationOrderDetail = true
    },
    openUpdateIndication (item) {
      this.showIndicationModal = true
      this.indicationOrderDetail = item
    },
    onHandleViewIndicationResult (data) {
      // if (!data.examination_results || !data.sign) {
      //   return
      // }

      // this.openUrl({ fileName: data.document_link })
      this.indicationDetail = data
      this.showIndicationDetail = true
    },
    openLink (link) {
      link && window.open(link, '_blank', 'noreferrer')
    },
    async openUrl ({ fileName, print = false }) {
      if (!fileName) {
        this.$toast.open({
          message: this.$t('Không tìm thấy bản in'),
          type: 'error'
        })
        return
      }

      try {
        this.isLoading = true
        // const res = await this.$rf.getRequest('AuthRequest').getEmrDocument({
        //   fileName
        // })
        // if (res.data) {
        //   if (print) {
        //     const printWindow = window.open(res.data?.documentLink)
        //     printWindow.print()

        //     printWindow.onafterprint = function () {
        //       printWindow.close()
        //     }
        //   } else {
        //     window.open(res.data?.documentLink)
        //   }
        // }
        this.openLink(fileName)
        this.isLoading = false
      } catch (error) {
        console.log(error)
        this.isLoading = false
      }
    },
    async downloadFile ({ name, open = true }) {
      if (!name) return
      const self = this
      const params = {
        fileName: name
      }
      let result = null
      const header = {
        'x-api-key': '4be5160e89e681cede9a80ae6db6af7c'
      }
      await self.$rf
        .getRequest('AuthRequest')
        .getLinkDownloadAwsRc(params, header, false)
        .then((r) => {
          if (r?.data?.data && open) {
            window.open(r.data.data)
          } else {
            result = r.data.data
          }
        })
        .catch((e) => {
          self.$toast.open({
            message: this.$t(
              'File không tồn tại hoặc gặp lỗi trong quá trình tải xuống'
            ),
            type: 'error'
          })
          console.error(e)
        })

      return result
    },
    isImage (file) {
      if (!file) return false
      if (file?.url && /(getimagefile)/.test(file?.url)) {
        return true
      }

      if (file instanceof File) {
        return !!file.type.match('image.*')
      }

      const extensions = ['jpg', 'jpeg', 'png', 'tiff', 'bmp', 'svg']
      const extVal = file?.file_name?.split('.').pop().toLowerCase()

      return extensions.includes(extVal)
    },
    getExtensionOfFile (filename) {
      if (!filename) return
      return filename?.split('.').pop().toLowerCase()
    },
    getStatus (stt) {
      return this.statuses.find((s) => s.id === stt) || this.statuses[0]
    },
    async getUrl (pacInfo) {
      if (!pacInfo?.id || pacInfo?.status !== 2 || !pacInfo?.accession) return

      const self = this
      const params = {
        accession: pacInfo.accession
      }
      return self.$rf
        .getRequest('AuthRequest')
        .getUrlPac(params)
        .then((r) => {
          if (r.data) {
            self.sendAmplitude('View PACS file', {
              'Person ID': this.$user.person?.id,
              'Person Name': this.$user.person?.name,
              Timestamp: moment(new Date()).format('HH:mm DD-MM-YYYY')
            })

            return r.data
            // console.log(appUtils.checkIsMobile())
            // alert('run')
            // if (appUtils.checkIsMobile()) {
            //   const linkElement = document.createElement('a')
            //   const linkId = `conversation_id_${appUtils.randomStr(6, true)}`
            //   linkElement.id = linkId
            //   linkElement.href = r.data
            //   window.document.body.appendChild(linkElement)

            //   const linkEl = document.getElementById(linkId)
            //   linkEl.setAttribute('rel', 'noopener noreferrer')
            //   !appUtils.checkIsMobile() &&
            //     linkEl.setAttribute('target', 'blank')
            //   linkEl.click()
            //   linkEl.remove()
            // } else {
            //   window.open(r.data, '_blank')
            // }
          }
        })
    },
    formatDMY (date) {
      return this.moment(date).format('DD/MM/YYYY')
    },
    downloadPacs (pac) {
      downloadS3.downloadFilePac(pac.file_name)
    },
    getUpdatedAt (item) {
      const date = moment(item.updated_at).add(7, 'hour')
      return appUtils.formatTimeDate(date)
    }
  }
}
</script>
<style lang="scss" scoped>
.select-doc {
  width: 100%;
  max-width: 1328px;
  padding: 16px;
  min-height: calc(100vh - 75px);
  margin: 0 auto;
}
.item-wraper {
  position: relative;
}
.delete-btn {
  position: absolute;
  top: 5px;
  right: 5px;
}
.item-wraper {
  height: 216px;
}
.upload {
  border: 0.756458px dashed #20409b;
}
.upload-btn {
  width: 100%;
  border: 0.756458px dashed #20409b;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-radius: 10px;
  cursor: pointer;
}
.disabled {
  opacity: 0.8;
  cursor: not-allowed !important;
  pointer-events: none;
}

.item-doc {
  text-decoration: initial;
}
</style>
