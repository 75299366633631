<template>
  <el-dialog
    center
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    class="cs-dialog"
    top="20px"
  >
    <div v-loading="loading" v-if="patientVisit && consultationData">
      <div
        v-if="consultationData"
        v-html="consultationData.content"
      ></div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'ModalViewConsultation',
  components: {},
  data () {
    return {
      dialogVisible: false,
      prescription: [],
      loading: false,
      prId: 0,
      patientVisit: null,
      consultationData: null
    }
  },
  computed: {
    consultationEdit () {
      if (this.patientVisit.consultation) {
        return this.patientVisit.consultation
      } else {
        return {
          reason: '',
          progression: '',
          diagnose: '',
          prehistoric: ''
        }
      }
    }
  },
  methods: {
    goToPrint () {
      // this.$router.push({ name: 'CaseIndicationsPrinter', params: { id: this.prop_indication_order.id } })
    },
    openEditConsulation () {
      this.dialogVisible = false
      this.$emit('openModify')
    },
    handleClose () {
      this.dialogVisible = false
      this.cancel()
    },
    openDialog (object, consultationId) {
      this.dialogVisible = true
      this.patientVisit = object
      this.consultationData = object.consultation.find(item => item.id === consultationId)
    },
    signDocument () {
      const params = {
        id: this.patientVisit.consultation.id,
        type: 1,
        method: 1
      }
      this.$rf
        .getRequest('DoctorRequest')
        .signDocument(params)
        .then(() => {
          this.$message({
            message: 'Thành công',
            type: 'success'
          })
          this.$emit('refresh')
        })
    },
    cancel () {
      this.prescription = []
    },
    formatDateDMY (dateTime) {
      return dateTime && window.moment(dateTime).format('DD/MM/YYYY')
    },
    getTime (dateTime) {
      return window.moment(dateTime).format('HH:mm')
    }
  }
}
</script>

<style lang="scss">
.ck-editor__editable {
  min-height: 5rem;
}
.cs-dialog {
  .el-dialog {
    min-width: 700px !important;
    overflow-x: auto;
    width: 80%;
  }
}

.cs-dialog {
  table {
    p {
      margin: 0;
    }
    width: 100%;
    td:first-child {
      vertical-align: top;

      width: 25%;
    }

    td:nth-child(2) {
      vertical-align: top;

      width: 50%;
    }

    td:last-child {
      vertical-align: top;
      width: 25%;
    }
  }
}
</style>
