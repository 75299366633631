<template>
  <div v-if="prescription && dialogVisible && prId">
    <el-dialog
      fullscreen
      :modal="false"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
    >
      <div class="relative">
        <div v-loading="loading">
          <div class="mb-3">
            <div class="text-center text-2xl font-semibold text-black">
              Chi tiết Đơn Thuốc
            </div>
          </div>
          <div class="my-3 text-base">
            <div class="row mb-2">
              <div class="col text-black">
                <span class="font-bold mr-1">Mã đơn thuốc:</span>
                <span>{{ `${prescription.prescription_code}` }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5 text-black">
                <div class="font-bold">Tên đơn thuốc</div>
                <div>{{ prescription.name }}</div>
              </div>
              <div class="col-md-4 text-black">
                <div class="font-bold">Ngày kê đơn</div>
                <div>{{ formatDate(prescription.date) }}</div>
              </div>
              <div class="col-md-3 text-black">
                <div class="font-bold">Bác sĩ kê đơn</div>
                <div>{{ prescription.prescribed_doctor }}</div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-5 text-black">
                <div class="font-bold">Cơ sở Y Tế</div>
                <div>{{ prescription.prescribed_clinic }}</div>
              </div>
              <div class="col-md-4 text-black">
                <div class="font-bold">Lời dặn của Bác sĩ</div>
                <div>{{ prescription.description }}</div>
              </div>
              <div class="col-md-3 text-black">
                <div class="font-bold">Ngày tạo</div>
                <div>{{ formatDate(prescription.created_at) }}</div>
              </div>
            </div>
          </div>

          <el-table
            class="text-black font-medium text-base w-full"
            :data="prescription.person_medicines"
            border
            :default-sort="{ prop: 'start_date', order: 'descending' }"
          >
            <el-table-column
              width="250"
              class-name="text-black text-base"
              label="Tên thuốc"
            >
              <template slot-scope="scope">
                <span>{{ handleGetMedicineName(scope.row) }}</span>
                <span>
                  {{ handleGetMedicineName(scope.row.medicine) }}
                  <!-- {{ scope.row.medicine && scope.row.medicine.name }} -->
                </span>
                <div class="text-sm truncate">
                  <span v-if="scope.row.side_effect"
                    >Tác dụng phụ: {{ scope.row.side_effect }}</span
                  >
                </div>
                <div v-if="scope.row.doctor_note" class="text-sm">
                  <span>Hướng dẫn: {{ scope.row.doctor_note }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column class-name="text-black text-base" label="ĐVT">
              <template slot-scope="scope">
                <span>
                  {{ scope.row.medicine && scope.row.medicine.amount_unit }}
                </span>
                <span>{{ scope.row.amount_unit }}</span>
              </template>
            </el-table-column>
            <el-table-column class-name="text-black text-base" label="SL">
              <template slot-scope="scope">
                <span>
                  {{ formatNumber(scope.row.amount) }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              class-name="text-black text-base"
              label="Lần / ngày"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.freq_per_day }} lần</span>
              </template>
            </el-table-column>
            <el-table-column
              class-name="text-black text-base"
              label="Lượng/lần"
            >
              <template slot-scope="scope">
                <span>
                  {{ formatNumber(scope.row.amount_per_one) }}
                  {{ scope.row.medicine && scope.row.medicine.amount_unit }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              class-name="text-black text-base"
              label="Lịch trình"
            >
              <template slot-scope="scope">
                <span>
                  {{
                    scope.row.gap_day &&
                    (scope.row.gap_day > 1
                      ? "Cách " + (Number(scope.row.gap_day) - 1)
                      : "Hàng")
                  }}
                  ngày
                </span>
              </template>
            </el-table-column>
            <!-- <el-table-column
          class-name="text-black text-base"
          label="Ngày bắt đầu"
          sortable
        >
          <template slot-scope="scope">
            <span >{{scope.row.start_date ? formatDate(scope.row.start_date) : ''}}</span>
          </template>
            </el-table-column>-->
            <!-- <el-table-column
          class-name="text-black text-base"
          label="Theo dõi"
        >
          <template slot-scope="scope">
            <div><input
                type="checkbox"
                v-model="scope.row.is_monitor"
                @click="changeDrugTracking(scope.row.id,scope.row.is_monitor, $event)"
                style="cursor: pointer;"
              ></div>
          </template>
            </el-table-column>-->
          </el-table>

          <div class="flex mt-3">
            <div class="ml-auto">
              <div class="flex">
                <!-- <div>
                  <button
                    type="button"
                    class="btn btn-light mr-1"
                    @click="handleClose"
                    :disabled="loading"
                  >
                    Đóng
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    class="btn bg-pri text-white mr-1"
                    @click="signDocument"
                    :disabled="loading || !!prescription.sign"
                  >
                    <div>{{ !!prescription.sign ? "Đã" : "" }} Xác nhận</div>
                  </button>
                </div>
                <el-tooltip
                  content="Phiếu đã xác nhận, không thể sửa"
                  placement="top"
                  :disabled="!prescription.sign"
                >
                  <div>
                    <button
                      type="button"
                      class="btn bg-pri text-white mr-1"
                      @click="editPrescription(prescription.id)"
                      :disabled="loading || !!prescription.sign"
                    >
                      <div>Sửa đổi</div>
                    </button>
                  </div>
                </el-tooltip> -->
                <!-- <div>
                  <button
                    type="button"
                    class="btn bg-pri text-white mr-1"
                    @click="() => save_pdf++"
                    :disabled="loading || !prescription.sign"
                  >
                    <div>Xem / In phiếu</div>
                  </button>
                </div> -->
              </div>
              <!-- <div class="ml-1 text-black" v-if="prescription.sign">
                ({{ prescription.sign.user.name }} đã xác nhận -
                {{ getDateTime(prescription.sign.sign_at) }})
              </div> -->
            </div>
          </div>
        </div>

        <ModalAddPrescription
          ref="ModalAddPrescription"
          @refresh="getPrescriptionByIDData"
        />
        <div v-if="patientVisit && prescription">
          <TempPrescription :save_pdf="save_pdf" :formData="formDataTemp" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import ModalAddPrescription from './ModalAdd'
import appUtils from '@/utils/appUtils'
import TempPrescription from './TempPrescription'
import { DOCUMENT_SIGN_TYPES } from '@/utils/constants'

export default {
  name: 'ModalViewPrescription',
  props: ['patientVisit'],
  components: { ModalAddPrescription, TempPrescription },
  data () {
    return {
      dialogVisible: false,
      prescription: {},
      loading: false,
      prId: 0,
      save_pdf: 0,
      documentLink: null
    }
  },
  mounted () {
    this.$root.$on('onSavePDFToS3', this.savePdfToS3)
  },
  destroyed () {
    this.$root.$off('onSavePDFToS3')
  },
  computed: {
    formDataTemp () {
      let sign = null

      if (this.prescription?.sign) {
        sign = {
          sign_at: window
            .moment(this.prescription?.sign?.sign_at)
            .subtract(7, 'hour'),
          signer_name: this.prescription?.sign?.user?.name,
          uuid: this.prescription?.sign?.uuid,
          isDocSign: false
        }
      }
      return {
        person: this.patientVisit?.person,
        prescription: this.prescription,
        consultation: this.patientVisit?.consultation,
        sign,
        icd_name: this.patientVisit?.icd_name
      }
    }
  },
  watch: {
    dialogVisible (stt) {
      if (!stt) {
        const query = Object.assign({}, this.$route.query)
        if (query.id) {
          delete query.id
          this.$router.push({ query }).catch(() => {})
        }
      } else {
        const query = Object.assign({}, this.$route.query)
        if (this.prId) {
          query.id = this.prId
          this.$router.push({ query }).catch(() => {})
        }
      }
    },
    patientVisit (data) {
      if (data) {
        if (this.prId) {
          const _tempData = data?.prescription?.find(
            (item) => item.id === this.prId
          )
          if (!_tempData || typeof _tempData === 'undefined') return

          this.documentLink = _tempData.document_link
        }
      }
    }
  },
  methods: {
    formatNumber (value) {
      return +value
    },
    handleClose () {
      this.dialogVisible = false
      this.cancel()
    },
    openDialog (id) {
      this.prId = id
      this.dialogVisible = true
      this.$nextTick(() => {
        if (id) {
          this.getPrescriptionByIDData(id)
        }
      })
    },
    cancel () {
      this.prescription = []
    },
    formatDate (date) {
      return date && moment(date).format('DD/MM/YYYY')
    },

    editPrescription () {
      this.$refs.ModalAddPrescription.openDialog(
        this.prescription,
        this.patientVisit
      )
    },
    isImage (file_name) {
      return file_name.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) != null
    },
    formatDateDMY (date) {
      return moment(date).format('HH:mm DD/MM/YYYY')
    },
    getLinkDownload (link) {
      return appUtils.getDocumentURL(link)
    },
    async getPrescriptionByIDData (id) {
      try {
        this.loading = true

        if (id) {
          const res = await this.$rf
            .getRequest('AuthRequest')
            .getPrescriptionByIDV2(id)

          if (res?.data) {
            this.prescription = _.cloneDeep(res.data)
            this.prescription = {
              ...this.prescription,
              person_medicines: this.prescription.person_medicine
            }
            this.loading = false
            // this.$emit('refresh')
          }
        }
      } catch (e) {
        console.log(e)
        this.loading = false
      }
    },

    async savePdfToS3 (base64) {
      if (!base64 || !this.prescription?.id) return

      try {
        this.loading = true

        const payload = {
          content: base64,
          fileName:
            `${appUtils.convertSlug(
              `${this.$t('Đơn Thuốc')} ${this.prescription?.created_at}`
            )}_` || 'prescription_',
          path: 'prescriptions',
          type: DOCUMENT_SIGN_TYPES.CODE.PRESCRIPTION,
          type_id: this.prescription?.id,
          uuid: this.prescription?.uuid
        }

        const res = await this.$rf
          .getRequest('AuthRequest')
          .printDocument(payload)

        if (res?.data) {
          this.loading = false
          this.$emit('refresh')
          // window.open(res.data?.documentLink, '_blank', 'noreferrer')
        }
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    openLink (link) {
      if (!link) return
      link && window.open(link, '_blank', 'noreferrer')
    },
    async openUrl ({ fileName }) {
      if (!fileName) {
        this.$toast.open({
          message: this.$t('Không tìm thấy bản in'),
          type: 'error'
        })
        return
      }
      window.open(fileName)
    },
    handleGetMedicineName (medicine) {
      const medicineName = medicine?.name || medicine?.product_info?.name || ''
      const activeIngredient =
        medicine?.active_ingredient ||
        medicine?.product_info?.active_ingredient_info?.name ||
        ''
      const quality =
        medicine?.quality || medicine?.product_info?.quality || ''

      return `${medicineName} ${quality} ${
        activeIngredient ? `(${activeIngredient})` : ''
      }`
    },
    getDateTime (date) {
      if (!date) return ''
      return window
        .moment(date)
        .subtract(7, 'hour')
        .format('HH:mm:ss DD/MM/YYYY')
    }
  }
}
</script>

<style lang="scss"></style>
