<template>
  <div v-if="patientVisit && patientVisit.id">
    <!-- Body -->
    <div class="rounded-lg p-3 hover:bg-gray-100 shadow-2xl bg-white mb-2">
      <div class="flex">
        <div class="fs-20 fw-500 ml-1">Phiếu khám</div>
      </div>
      <div v-if="patientVisit.consultation && patientVisit.consultation.length > 0">
        <div v-for="item in patientVisit.consultation" :key="item.id">
          <div
            @click="modalPreviewConsulation(item)"
            class="mt-2"
          >
            <div class="mb-2">
              <div class="mb-1 flex">
                <div class="inline-block" role="button">
                  <div
                    class="flex items-center fs-15 fw-500 txt-pri hover:text-blue-600 transition-all duration-200"
                  >
                    <span class="mr-1">Phiếu khám {{ appUtils.formatUTCTimeDate(item.created_at) }}</span>
                  </div>
                  <div class="text-xs italic">
                  <span class="mr-1">
                    {{
                      item.sign ? "Đã ký:" : "Đã lưu:"
                    }}
                  </span>
                    <span>{{ getSignAt(item) }}</span>
                  </div>
                </div>
                <div v-if="item.sign" class="ml-auto" :set="(status = getApptStatus(item.sign))">
                  <div :class="status.class" class="border rounded-full">
                    <div class="py-1 px-2">{{ status.text }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PreviewConsultationModal
      ref="PreviewConsultationModal"
      @refresh="refresh"
      @openModify="modalUploadConsulationAppt()"
    />
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
import PreviewConsultationModal from './Consultation/PreviewConsultationModal.vue'

export default {
  name: 'AddConsulationTemp',
  components: {
    PreviewConsultationModal
  },
  props: {
    patientVisit: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isShowModal: false,
      consulation: {},
      appUtils
    }
  },
  methods: {
    refresh () {
      this.$emit('refresh')
    },
    openPreviewAndRefresh () {
      this.modalPreviewConsulation(true)
      this.$emit('refresh')
    },
    getApptStatus (stt) {
      if (stt) {
        return {
          class: 'text-green-600 border-green-600 bg-green-50',
          text: 'Đã ký'
        }
      } else if (this.patientVisit?.consultation?.confirm_status === 2) {
        return {
          class: 'text-green-600 border-green-600 bg-green-50',
          text: 'Đã xác nhận'
        }
      } else {
        return {
          class: 'text-red-600 border-red-600 bg-red-50',
          text: 'Chưa ký'
        }
      }
    },
    // modal add consulation
    modalUploadConsulationAppt () {
      this.$refs.ModifyConsultationModal.openDialog(this.patientVisit)
    },
    // modal preview consultation
    modalPreviewConsulation (item) {
      if (item?.sign?.id && item?.document_link) {
        // this.$refs.PdfViewer.handleOpen(
        //   item?.document_link,
        //   ENVELOPE_DOCUMENT_TYPE.EXAMINATION,
        //   item.id
        // )
        window.open(item?.document_link, '_blank')
      } else {
        this.$refs.PreviewConsultationModal.openDialog(
          this.patientVisit,
          item.id
        )
      }

      // window.$('#modalPreviewConsulation').modal(show ? 'show' : 'hide')
    },
    // modal preview consulation
    // modalPreviewConsulation () {
    //   if (this.patientVisit.consultation?.document_link) {
    //     this.openUrl({
    //       fileName: this.patientVisit.consultation?.document_link
    //     })
    //   } else {
    //     this.$refs.PreviewConsultationModal.openDialog(this.patientVisit)
    //   }
    //
    //   // window.$('#modalPreviewConsulation').modal(show ? 'show' : 'hide')
    // },
    openEditConsulation () {
      this.$refs.ModifyConsultationModal.openDialog(this.patientVisit)
      this.modalPreviewConsulation(false)
    },
    openLink (link) {
      link && window.open(link, '_blank', 'noreferrer')
    },
    async openUrl ({ fileName, print = false }) {
      if (!fileName) {
        this.$toast.open({
          message: this.$t('Không tìm thấy bản in'),
          type: 'error'
        })
        return
      }

      try {
        this.isLoading = true
        // const res = await this.$rf.getRequest('AuthRequest').getEmrDocument({
        //   fileName
        // })
        // if (res.data) {
        //   if (print) {
        //     const printWindow = this.openLink(res.data?.documentLink)
        //     printWindow.print()

        //     printWindow.onafterprint = function () {
        //       printWindow.close()
        //     }
        //   } else {
        //     this.openLink(res.data?.documentLink)
        //   }
        // }
        this.openLink(fileName)
        this.isLoading = false
      } catch (error) {
        console.log(error)
        this.isLoading = false
      }
    },
    getSignAt (item) {
      let time

      if (item.sign) {
        time = window
          .moment(item.sign?.sign_at)
          .format('HH:mm:ss DD/MM/YYYY')
      } else {
        time = window
          .moment(item.updated_at)
          .format('HH:mm:ss DD/MM/YYYY')
      }

      // return appUtils.formatDateTimeFull(time)
      return time
    }
  }
}
</script>
