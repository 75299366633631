<template>
  <el-dialog :visible.sync="showIndicationOrderDetail" :before-close="onHideModal" width="75%">
    <div v-if="indicationOrderDetail" v-loading="isLoading">
      <div class="border-b border-l-0 border-t-0 border-r-0 border-solid border-gray-200 pb-2 mb-3">
        <div class="flex flex-wrap items-center justify-between -mx-3">
          <div class="px-3">
            <h4 class="mb-0">{{ indicationOrderDetail.name }}</h4>
            <div class="text-xs italic">
              <span class="mr-1">Cập nhật lần cuối:</span>
              <span>{{ appUtils.formatTimeDate(indicationOrderDetail.updated_at) }}</span>
            </div>
          </div>
          <div class="hidden lg:block px-3" v-if="indicationOrderDetail.sign">
            <div role="button" class="text-xs text-center leading-none">
              <div class="font-bold mb-1">{{ $t('Đã ký phiếu') }}</div>
              <div class="mb-1">{{ indicationOrderDetail.sign.user.name }}</div>
              <div>{{ getHMDMYVN(indicationOrderDetail.sign.sign_at) }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3 lg:mb-0">
        <h5 class="mb-2">{{ 'Danh sách chỉ định' }}</h5>
        <div>
          <div v-for="(item) in indicationOrderDetail.indication" :key="item.id" class="mb-2">
            <h6
              class="border-b border-l-0 border-t-0 border-r-0 border-solid border-gray-200 bg-gray-100 py-1 px-2 mb-0"
            >
              <span>{{ item.name }}</span>
            </h6>
            <div class="flex flex-wrap lg:justify-between lg:items-center p-2">
              <div class="w-full lg:w-1/3">
                <i class="el-icon-map-location" />
                <span class="ml-1">
                  {{
                  item.target_name
                  ? item.target_name
                  : item.target_id
                  ? getNameTargetIndication(item.target_id)
                  : 'Chưa có nơi thực hiện'
                  }}
                </span>
              </div>
              <div class="w-full lg:w-1/3 lg:text-center">
                <i class="el-icon-date" />
                <span
                  class="ml-1"
                >{{ item.due_date ? appUtils.formatDate(item.due_date) : 'Chưa có ngày đến hạn' }}</span>
              </div>
              <div
                class="w-full lg:w-1/3 italic lg:text-right"
                :set="status = getIndicationStatus(item)"
              >
                <span :class="status.class">{{ status.text }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lg:hidden px-3" v-if="indicationOrderDetail.sign">
        <div role="button" class="text-xs text-center leading-none">
          <div class="font-bold mb-1">{{ $t('Đã ký phiếu') }}</div>
          <div class="mb-1">{{ indicationOrderDetail.sign.user.name }}</div>
          <div>{{ getHMDMYVN(indicationOrderDetail.sign.sign_at) }}</div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<style lang="scss" scoped>
::v-deep {
  .hodo-el {
    .el-textarea__inner,
    .el-input__inner {
      background: white !important;
      border: 1px solid #dee2e6 !important;
    }
  }
}
</style>

<script>
import _ from 'lodash'
import appUtils from '../../../utils/appUtils'
import { INDICATION_STATUS } from '../../../utils/constants'
import dataDemo from '../../../pages/ElectronicMedicalRecords/components/Indications/ModalIndication/dataMemo'

export default {
  name: 'CUInficationOrderTemp',
  props: {
    indicationOrderData: {
      type: Object,
      required: true
    },
    type: {
      type: Number,
      required: true
    },
    showIndicationOrderDetail: {
      type: Boolean,
      default: false
    }
  },
  data () {
    const defaultForm = {}

    return {
      demo: dataDemo,
      isLoading: false,
      defaultForm,
      form: defaultForm,
      appUtils,
      debounce: null,
      typing: false,
      indicationOrderDetail: null,
      INDICATION_STATUS
    }
  },
  created () {
    this.indicationOrderDetail = this.indicationOrderData
  },
  mounted () {},
  watch: {
    indicationOrderData (data) {
      this.isLoading = true

      if (data) {
        this.indicationOrderDetail = data
      }
    }
  },
  computed: {},
  methods: {
    onHideModal () {
      this.$emit('hideModal')
    },
    getIndicationStatus (data) {
      if (data.examination_results && data.sign) {
        return {
          text: this.$t('Đã có KQ'),
          class: 'text-green-700'
        }
      } else {
        return {
          text: this.$t('Đang cập nhật'),
          class: 'text-yellow-600'
        }
      }
      // switch (stt) {
      //   case INDICATION_STATUS.CODE.PENDING:
      //     return {
      //       text: this.$t('Đang cập nhật'),
      //       class: 'text-yellow-600'
      //     }
      //   case INDICATION_STATUS.CODE.HAS_RESULT:
      //     return {
      //       text: this.$t('Đã có KQ'),
      //       class: 'text-green-700'
      //     }
      //   case INDICATION_STATUS.CODE.CS:
      //     return {
      //       text: this.$t('Có dấu hiệu bất thường'),
      //       class: 'text-red-600'
      //     }
      //   case INDICATION_STATUS.CODE.NCS:
      //     return {
      //       text: this.$t('Không có dấu hiệu bất thường'),
      //       class: 'text-blue-800'
      //     }

      //   default:
      //     break
      // }
    },
    getNameTargetIndication (id) {
      const data = _.find(this.demo, { id })
      return data.name
    },
    onHandleOpenUpdateIndication () {
      this.onHideModal()
      this.$emit('openUpdateIndication', this.indicationOrderDetail)
    },
    goToPrint () {
      this.$router.push({
        name: 'CaseIndicationsPrinter',
        params: { id: this.indicationOrderDetail.id }
      })
    },
    getHMDMYVN (date) {
      if (!date) return
      const dt = this.moment(date)
      const hm = dt.clone().format('HH:mm')
      const d = dt.clone().format('DD')
      const m = dt.clone().format('MM')
      const y = dt.clone().format('YYYY')
      return hm + ', ngày ' + d + ' tháng ' + m + ' năm ' + y
    }
  }
}
</script>
