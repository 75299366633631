<template>
  <div>
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div class="col">
        <div v-if="patientVisit" class="m-3 px-2">
          <div
            class="row position-relative p-1 rounded-lg bg-white text-black border-current shadow-2xl"
          >
            <div class="col-2 flex items-center">
              <el-tooltip :content="$t('Quay lại')" placement="right">
                <div
                  @click="goBack"
                  role="button"
                  class="inline-block w-10 h-10 bg-white rounded-circle overflow-hidden"
                >
                  <span
                    class="flex w-10 h-10 align-middle border rounded-circle hover:bg-blue-100 transition-all duration-200"
                  >
                    <i class="el-icon-back m-auto text-2xl" />
                  </span>
                </div>
              </el-tooltip>
            </div>
            <div class="col-8 flex items-center justify-center">
              <div class="p-0 m-0 fs-24 fw-500 text-center">
                Thông tin lượt khám
              </div>
            </div>
            <div class="col-2"></div>
            <!-- <div class="col-6 flex justify-center">
              <div class="my-3">
                <span class="fs-30 fw-500 text-primary">STT {{ patientVisit.line_number }}</span>
              </div>
            </div> -->
            <!-- <div class="col-3"></div> -->
          </div>
        </div>
        <div v-if="patientVisit" class="relative row m-3">
          <div class="col-md-6">
            <div
              class="rounded-lg bg-white text-black border-current shadow-2xl mb-2"
            >
              <div class="p-3">
                <div
                  class="flex flex-col md:flex-row gap-2 justify-between w-full"
                >
                  <div
                    class="w-full"
                    :set="(status = getApptStatus(patientVisit.status))"
                  >
                    <div :class="status.class" class="border rounded-full">
                      <div class="py-1 px-2 text-center">{{ status.text }}</div>
                    </div>
                  </div>
                </div>
                <div class="mt-3">
                  <div class="row">
                    <p class="text-secondary col-4">Họ và tên:</p>
                    <p class="col-8">
                      {{ patientVisit.person ? patientVisit.person.name : "" }}
                    </p>
                  </div>
                  <div class="row">
                    <p class="text-secondary col-4">Giới tính:</p>
                    <p class="col-8">
                      {{
                        patientVisit.person
                          ? getGender(patientVisit.person.gender)
                          : ""
                      }}
                    </p>
                  </div>
                  <div class="row">
                    <p class="text-secondary col-4">Ngày sinh:</p>
                    <p class="col-8">
                      {{
                        patientVisit.person &&
                        getPersonInfoString(patientVisit.person)
                      }}
                    </p>
                  </div>
                  <div class="row">
                    <p class="text-secondary col-4">Điện thoại:</p>
                    <p class="col-8">
                      {{ patientVisit.person ? patientVisit.person.phone : "" }}
                    </p>
                  </div>
                  <div class="row">
                    <p class="text-secondary col-4">Địa chỉ:</p>
                    <p class="col-8">
                      {{ patientVisit.person && patientVisit.person.address }}
                    </p>
                  </div>
                  <div class="row">
                    <p class="text-secondary col-4">Hình thức:</p>
                    <p class="col-8">
                      {{
                        patientVisit.method == 1
                          ? "Tại cơ sở ý tế"
                          : "Tư vấn từ xa"
                      }}
                    </p>
                  </div>
                  <div class="row">
                    <p class="text-secondary col-4">Bác sĩ:</p>
                    <p class="col-8">
                      {{ patientVisit.doctor ? patientVisit.doctor.name : "" }}
                    </p>
                  </div>
                  <div class="row">
                    <p class="text-secondary col-4">Thời gian:</p>
                    <p class="col-8">
                      {{ getTime(patientVisit.start_time) }} -
                      {{ formatDateDMY(patientVisit.start_time) }}
                    </p>
                  </div>
                  <div class="row">
                    <p class="text-secondary col-4">Lý do khám:</p>
                    <p class="col-8">
                      {{ patientVisit.visit_reason || "Không có lý do khám" }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <UploadFile
              :patient-visit-files="patientVisit.patient_visit_file"
              :id="patientVisit.id"
              :patient-status="patientVisit.status"
              @refresh="getPatientVisit"
            />
          </div>

          <div class="rounded-lg text-black border-current col-md-6">
            <div
              class="rounded-lg p-3 hover:bg-gray-100 shadow-2xl bg-white mb-2"
            >
              <SelectDiseaseICD10 :dataIcd="diseaseICD" />
            </div>
            <ConsulationTemp
              :patient-visit="patientVisit"
              @refresh="getPatientVisit"
            />
            <PrescriptionTemp
              :patient-visit="patientVisit"
              @refresh="getPatientVisit"
            />
            <indication-temp
              :name="$t('Xét nghiệm')"
              :type="INDICATION_TYPES.CODE.TEST_FORM"
              :consultation="consultationSms"
              :patient-visit="patientVisit"
              @refresh="getPatientVisit"
            />
            <indication-temp
              :name="$t('Chẩn đoán hình ảnh')"
              :type="INDICATION_TYPES.CODE.ULTRASOUND"
              :consultation="consultationSms"
              :patient-visit="patientVisit"
              @refresh="getPatientVisit"
            />
            <indication-temp
              :name="$t('Chỉ định khác')"
              :type="INDICATION_TYPES.CODE.OTHERS"
              :consultation="consultationSms"
              :patient-visit="patientVisit"
              @refresh="getPatientVisit"
            />
            <indication-temp
              :name="$t('Sinh học phân tử')"
              :type="INDICATION_TYPES.CODE.MOLECULAR_BIOLOGY"
              :consultation="consultationSms"
              :patient-visit="patientVisit"
              @refresh="getPatientVisit"
            />
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>
<script>
import appUtils from '../../../utils/appUtils'
import statusAppt from '../../../constants/statusAppt'
import IndicationTemp from '../../../components/PatientVisit/IndicationTemp.vue'
import PrescriptionTemp from '../../../components/PatientVisit/Prescription.vue'
import UploadFile from '../../../components/PatientVisit/UploadFilesTemp.vue'
import ConsulationTemp from '../../../components/PatientVisit/ConsulationTemp.vue'
import { EXAMINATION_STATUS, INDICATION_TYPES } from '../../../utils/constants'
import { BOverlay } from 'bootstrap-vue'
import SelectDiseaseICD10 from '../PatientVisit/SelectDiseaseICD10/index.vue'

export default {
  name: 'PatientVisitDetail',
  components: {
    // BRow,
    // BCol,
    BOverlay,
    ConsulationTemp,
    IndicationTemp,
    PrescriptionTemp,
    UploadFile,
    SelectDiseaseICD10
  },
  computed: {
    isDisabledResult () {
      if (this.patientVisit) {
        if (this.patientVisit.consultation) {
          if (this.patientVisit.consultation.sign) {
            return false
          }
        }
        if (this.patientVisit.prescription) {
          if (this.patientVisit.prescription.find((e) => e.sign != null)) {
            return false
          }
        }
        if (this.patientVisit.indication_order) {
          if (this.patientVisit.indication_order.find((e) => e.sign != null)) {
            return false
          }
        }
      }
      return true
    },
    isShowPrescription () {
      return this.patientVisit?.prescription?.some((e) => e?.document_link)
    }
  },
  data () {
    return {
      loading: false,
      DialogVisibleStatus: false,
      consultationSms: {},
      root_type: 1,
      appointment: null,
      clinic_id: null,
      doctor_id: null,
      request_id: null,
      request: null,
      person: null,
      person_id: null,
      appt_edit: null,
      selectedHr: null,
      statusAppt,
      valueStatus: '',
      patientVisit: null,
      INDICATION_TYPES,
      diseaseICD: {}
    }
  },
  created () {
    const self = this
    if (self.$route?.params?.id) {
      self.getPatientVisit()
    } else {
      self.$router.push({ path: '/doctor/appointment-management' })
      return false
    }
  },
  mounted () {
    const self = this

    // let user = this.$user
    self.clinic_id = this.$clinicId
  },
  destroyed () {
    this.$bus.off('change:status')
  },
  methods: {
    async getPatientVisit () {
      this.loading = true
      try {
        const self = this
        const id = self.$route.params.id
        await self.$rf
          .getRequest('AuthRequest')
          .getPatientVisitsById(id)
          .then((res) => {
            this.loading = false
            self.patientVisit = res.data
            self.diseaseICD = {
              code: res.data?.icd_code || '',
              name: res.data?.icd_name || ''
            }
            self.consultationSms = {
              id: self.patientVisit?.appt?.consultation?.id,
              patient_visit_id: self.patientVisit?.id,
              health_record_id:
                self.patientVisit?.appt?.consultation?.health_record_id,
              indication_order: self.patientVisit?.indication_order
            }
          })

        // if (self.patientVisit?.appt?.consultation?.id) {
        //   await self.getConsultationById(
        //     self.patientVisit?.appt?.consultation?.id
        //   )
        // }
      } catch (error) {
        console.log('', error)
      }
    },
    getApptStatus (stt) {
      switch (stt) {
        case EXAMINATION_STATUS.CODE.WAITING:
          return {
            class: 'text-yellow-600 border-yellow-600 bg-yellow-50',
            text: 'Chờ khám'
          }
        case EXAMINATION_STATUS.CODE.WAITING_RESULT:
          return {
            class: 'text-red-600 border-red-600 bg-red-50',
            text: 'Chờ kết quả'
          }
        case EXAMINATION_STATUS.CODE.BEING:
          return {
            class: 'text-green-600 border-green-600 bg-green-50',
            text: 'Đang khám'
          }
        case EXAMINATION_STATUS.CODE.DONE:
          return {
            class: 'text-blue-600 border-blue-600 bg-blue-50',
            text: 'Đã khám xong'
          }
        case EXAMINATION_STATUS.CODE.CANCELED:
          return {
            class: 'text-red-600 border-red-600 bg-red-50',
            text: 'Đã hủy lượt'
          }
        case EXAMINATION_STATUS.CODE.WAITING_READ_RESULT:
          return {
            class: 'text-indigo-600 border-indigo-600 bg-indigo-50',
            text: 'Chờ đọc kết quả'
          }
        case EXAMINATION_STATUS.CODE.MISSING:
          return {
            class: 'text-gray-600 border-gray-600 bg-gray-50',
            text: 'Nhỡ lượt'
          }
        default:
          return {
            class: 'text-yellow-600 border-yellow-600 bg-yellow-50',
            text: 'Chờ khám'
          }
      }
    },
    async getConsultationById (id) {
      try {
        const self = this
        await this.$rf
          .getRequest('DoctorRequest')
          .getConsultationByIDV2(id)
          .then((res) => {
            self.consultationSms = res.data
            console.log(self.consultationSms)
          })
      } catch (error) {
        console.log('', error)
      }
    },
    async ApptTreatment (value) {
      const params = {
        treatment_type: value
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .ApptTreatment(this.appointment.id, params)
      this.getAppts()
    },
    async ApptStatus (value) {
      const params = {
        status: value
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .ApptStatus(this.appointment.id, params)
      this.getAppts()
    },
    goBack () {
      this.$router.push({
        path: `/health-records/${this.patientVisit.health_record_id}`
      })
    },
    onEditAppt () {
      if (!this.appointment) return
      this.$router.push({
        path: `/doctor/appointment-update/${this.appointment.id}`
      })
    },
    onShowModalAppt (show) {
      window.$('#modal__appt').modal(show ? 'show' : 'hide')
    },
    getImageURL (avatar) {
      return appUtils.getImageURL(avatar)
    },
    getTime (dateTime) {
      return window.moment(dateTime).format('HH:mm')
    },
    formatDateDMY (dateTime) {
      return dateTime && window.moment(dateTime).format('DD/MM/YYYY')
    },
    videoCall () {
      if (!this.appointment) return

      this.$router.push({
        name: 'ApptCall',
        params: { id: this.appointment.id }
      })
    },
    getInvoiceStatus (appointment) {
      if (!appointment || !appointment.invoice) return false
      const stt = appointment.invoice.status
      const statuses = {}
      statuses[1] = {
        text: this.$t('schedule_page.lbl_paysts_unpaid'),
        class: 'text--red'
      }
      statuses[2] = {
        text: this.$t('schedule_page.lbl_paysts_wait_confirm'),
        class: 'text--yellow'
      }
      statuses[3] = {
        text: this.$t('schedule_page.lbl_paysts_paid'),
        class: 'text--green'
      }
      return stt && statuses[stt] ? statuses[stt] : statuses[1]
    },

    getPersonInfoString (p) {
      return (
        this.formatDateDMY(p.birthday) +
        ' (' +
        this.getAges(p.birthday) +
        ')' +
        (p.career ? ', ' + p.career : '')
      )
    },
    getGender (gender = 1) {
      return gender === 1
        ? 'Nam'
        : gender === 2
          ? 'Nữ'
          : gender === 3
            ? 'Khác'
            : ''
    },
    getAges (date) {
      const bd = window.moment(date)
      const age = window.moment().diff(bd, 'years')
      return age + 'tuổi'
    },
    setState (value) {
      return [0, 1].includes(value)
        ? this.$t('schedule_page.lbl_sts_wait')
        : value === 2
          ? this.$t('schedule_page.lbl_sts_examining')
          : value === 3
            ? this.$t('schedule_page.lbl_sts_wait_result')
            : value === 4
              ? this.$t('schedule_page.lbl_sts_wait_result')
              : value === 5
                ? this.$t('schedule_page.lbl_sts_finished')
                : this.$t('schedule_page.lbl_cancel_visit')
    },
    getStatusAppt (status) {
      if (status === undefined || status === null) return ''
      return this.statusAppt[status]
    },
    async updateStatusPatient (stt) {
      this.patientVisit.status = stt
      this.loading = true
      await this.$rf
        .getRequest('DoctorRequest')
        .updatePatientVisitsById(this.patientVisit.id, this.patientVisit)
        .then((res) => {
          console.log(res)
          this.loading = false
          // this.getPatientVisit()
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }
}
</script>
<style lang="css">
#appointment_detail_img {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.appointment-person-info .person-name {
  padding-left: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.appointment-person-info .person-avatar .avatar {
  --tblr-avatar-size: 64px;
}

.header-title {
  width: calc(100% - 32px);
}

.btn-c {
  background-color: #20419b;
  color: white;
  border: none;
}

.btn-d {
  background-color: #1a80fd;
  color: white;
  border: none;
}
</style>
