<template>
  <div class="rounded-lg p-3 shadow-2xl bg-white mb-2">
    <div class="flex">
      <div class="fs-20 fw-500 ml-1">Đơn thuốc</div>
    </div>

    <div class="mt-2">
      <div
        class="mb-2"
        v-for="item in patientVisit.prescription"
        :key="item.id"
      >
        <div @click="viewDetail(item)" class="mb-1 flex">
          <div class="inline-block" role="button">
            <div
              class="flex items-center fs-15 fw-500 txt-pri hover:text-blue-600 transition-all duration-200"
            >
              <span class="mr-1">{{ item.name }}</span>
            </div>
            <div class="text-xs italic">
              <span class="mr-1">Cập nhật lần cuối:</span>
              <span>{{ appUtils.formatTimeDate(item.updated_at) }}</span>
            </div>
          </div>
          <div class="ml-auto" :set="(status = getApptStatus(item))">
            <div :class="status.class" class="border rounded-full">
              <div class="py-1 px-2">{{ status.text }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalAddPrescription
      ref="ModalAddPrescription"
      @refresh="getPersonMedicine"
    />
    <ModalViewPrescription
      ref="ModalViewPrescription"
      :patient-visit="patientVisit"
      @refresh="getPersonMedicine"
      :disease="disease"
    />
  </div>
</template>
<script>
import ModalAddPrescription from './Prescription/ModalAdd.vue'
import ModalViewPrescription from './Prescription/ModalView.vue'
import appUtils from '../../utils/appUtils'

export default {
  name: 'Prescription',
  components: {
    ModalAddPrescription,
    ModalViewPrescription
  },
  props: {
    patientVisit: {
      type: Object,
      required: true
    },
    disease: {
      type: Object
    }
  },
  data () {
    return {
      appUtils
    }
  },
  methods: {
    getApptStatus (item) {
      if (item?.sign) {
        return {
          class: 'text-green-600 border-green-600 bg-green-50',
          text: 'Đã ký'
        }
      } else if (item?.confirm_status === 2) {
        return {
          class: 'text-green-600 border-green-600 bg-green-50',
          text: 'Đã xác nhận'
        }
      } else {
        return {
          class: 'text-red-600 border-red-600 bg-red-50',
          text: 'Chưa ký'
        }
      }
    },
    createPrescription () {
      this.$refs.ModalAddPrescription.openDialog(null, this.patientVisit)
    },
    getPersonMedicine () {
      this.$emit('refresh')
    },
    formatDateDMY (dateTime) {
      return dateTime && window.moment(dateTime).format('DD/MM/YYYY')
    },
    getTime (dateTime) {
      return window.moment(dateTime).format('HH:mm')
    },
    viewDetail (prescription) {
      if (prescription.document_link) {
        this.openUrl({ fileName: prescription.document_link })
      } else {
        this.$refs.ModalViewPrescription.openDialog(prescription.id)
      }
    },
    openLink (link) {
      link && window.open(link, '_blank', 'noreferrer')
    },
    async openUrl ({ fileName, print = false }) {
      if (!fileName) {
        this.$toast.open({
          message: this.$t('Không tìm thấy bản in'),
          type: 'error'
        })
        return
      }

      try {
        this.isLoading = true
        // const res = await this.$rf.getRequest('AuthRequest').getEmrDocument({
        //   fileName
        // })
        // if (res.data) {
        //   if (print) {
        //     const printWindow = this.openLink(res.data?.documentLink)
        //     printWindow.print()

        //     printWindow.onafterprint = function () {
        //       printWindow.close()
        //     }
        //   } else {
        //     this.openLink(res.data?.documentLink)
        //   }
        // }
        this.openLink(fileName)
        this.isLoading = false
      } catch (error) {
        console.log(error)
        this.isLoading = false
      }
    }
  }
}
</script>
